export { default as Login } from "./Login"
export { default as Home } from "./Home"
export { default as Header } from "./Header"
export { default as Signin } from "./Signin"
export { default as Aboutus } from "./Aboutus"
export { default as Premium } from "./premium"
export { default as Project } from "./project"
export { default as ChatBot } from "./Chatbot"
export { default as ForgotPass } from "./ForgotPass"
export { default as ResetPass } from "./ResetPass"
export { default as ErrorPage } from "./ErrorPage"
export { default as Gemini } from "./GeminiChatbot"
export { default as Payment } from "./Payment"
export { default as PrivacyPolicy } from "./ChinhSach"
export { default as TermsAndConditions } from "./DieuKhoang"
