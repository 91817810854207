import React ,{useCallback, useState, useEffect}from 'react'
import { motion } from 'framer-motion'
import styles from '../../styles'
import { fadeIn, slideIn, staggerContainer, textVariant, startingFeatures } from '../../utils/motion'
import logo from '../../assets/logo-01.png';
import homeimg from '../../assets/homeimg.svg';
import homeimg1 from '../../assets/homeimg1.svg';
import homeimg2 from '../../assets/homeimg2.svg';
import  "../../styles/main.css";
import { Button } from "../../components"
import imarrow from "../../utils/arrow"
import { StartSteps, TitleText, TypingText } from '../../components/customtext';
import StepsComponent from "../../components/StepsComponent"
import Footer from "../../components/Footer"
import Header from "../public/Header"
import chatbotlogo from "../../assets/Chat bot2-05.png"
import chatbotlogov1 from "../../assets/Chat bot.3png-05.png"
import chatbotlogov2 from "../../assets/Artboard 7.png"
import MailBox from "../../components/MailBox"
import BuildJourney from "../../components/Journey"
import { useNavigate } from 'react-router-dom'
import { path } from "../../utils/constant"
import { FaRobot, FaTools, FaUsers, FaBrain } from 'react-icons/fa';
import ServiceCard from "../../components/Overview"
import { FaBusinessTime, FaChartLine, FaBuilding, FaLightbulb, FaCogs } from 'react-icons/fa';
import StartupConsulting from "./../../components/TuVan"
import { Helmet } from 'react-helmet';

const images = [homeimg, homeimg1, homeimg2]; // Mảng chứa các đường dẫn ảnh
let currentIndex = 0;
const Home = () => {
  const categories = [
    { id: 1, icon: <FaRobot className="w-16 h-16 mb-2 opacity-70" />, name: "MẠNH MẼ", description: "Sử dụng công nghệ AI tiên tiến" },
    { id: 2, icon: <FaTools className="w-16 h-16 mb-2 opacity-70" />, name: "NÂNG CẤP", description: "Bao gồm các khả năng mạnh mẽ và hữu ích" },
    { id: 3, icon: <FaUsers className="w-16 h-16 mb-2 opacity-70" />, name: "ĐỒNG NGHIỆP", description: "Hơn cả một AI, Cynobot là một người đồng nghiệp hữu ích" },
    { id: 4, icon: <FaBrain className="w-16 h-16 mb-2 opacity-70" />, name: "TRÍ TUỆ", description: "Hỗ trợ bạn trong mọi vấn đề về kinh tế" }
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [currentDotIndex, setCurrentDotIndex] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length; // Tăng chỉ số ảnh và quay lại đầu nếu cần
      setCurrentImage(images[currentIndex]); // Cập nhật hình ảnh
      setCurrentDotIndex((prevIndex) => (prevIndex + 1) % images.length); // Cập nhật chỉ số dot
    }, 3000); // 3 giây

    return () => clearInterval(interval); // Dọn dẹp interval khi component unmount
  }, []);
  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token);
    };

    checkLoginStatus();

    window.addEventListener("storage", checkLoginStatus);

    return () => {
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, []); 
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };
  const navigate = useNavigate()
  const goSignin = useCallback(() => {
    navigate(path.SIGNIN)
  },[])
  const goChat = useCallback(() => {
    navigate(path.CHATBOT)
  },[])
  
  return (
    // S1
    <>
    <Helmet>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PVC66RPL');`}
      </script>
    </Helmet>
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVC66RPL" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
    <div className='flex-col m-auto '>        
      <Header/>
        <div className='flex items-start justify-start'>
        <section className={`${styles.yPaddings} w-1/2 sm:pl-16 pl-16`}>
          <motion.div
            variants={staggerContainer} 
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto flex flex-col items-center justify-center`}>
            <div className="flex items-center flex-col relative z-10">
            <motion.h1 variants={textVariant(0.5)} className={styles.heroHeading}>
            WE ARE WORTHY OF YOUR
            </motion.h1>

            <motion.div variants={textVariant(1.1)} className={styles.heroDText}>
            start-up
            </motion.div>
            <motion.div variants={textVariant(1.2)} className={styles.herosText}>
            Khởi nghiệp đi, ngại ngần chi mọi thứ đã có Cynosure cùng bạn đi đến thành công.
            </motion.div>

            <motion.div className='flex gap-2 mx-10 my-7 object-cover justify-end' variants={textVariant(1.2)}>
            <div>
      {isLoggedIn ? (
        <>
          <Button 
            text={'Chat ngay'} 
            textColor='text-black' 
            bgColor='gradient-01' 
            IcAfter={imarrow}
            onClick={goChat}
          />

        </>
      ) : (
        <Button 
          text={'Đăng ký ngay'} 
          textColor='text-black' 
          bgColor='gradient-01' 
          IcAfter={imarrow}
          onClick={goSignin}
        />
      )}
    </div>
              </motion.div>
              
      </div>
      
          </motion.div>
          
        </section>
        
     {/* S2 */}
        <section className='w-1/2 flex items-end justify-end'>
        <motion.div
           variants={staggerContainer} 
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto flex flex-col items-center justify-center`}>

            <motion.div variants={fadeIn('right', 'tween', 0.2, 1)}>
            <img
              src={logo}
              alt='Logo'
              className='sm:h-auto object-contain'
            />
            </motion.div>
          </motion.div>
        </section>
        
        </div>

        <div className='flex-col m-auto '>

        {/* S3 */}
          <section className={`${styles.paddings} relative z-10`}>
          <div className="gradient-02 z-0" />
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
            style={{maxWidth: 'calc(100% - 10vw)'}}
          >
            <TypingText title="| About Cynosure" textStyles="text-center" />

            <motion.p
              variants={fadeIn('up', 'tween', 0.2, 1)}
              className="mt-[0.8vw] font-normal sm:text-[2vw] text-[1.5vw] text-center text-secondary-white my-10"
            >
              <span className="font-extrabold text-green-500">Cynosure</span> <span  className="text-white" > hân hạnh khi là một 
              cầu nối giữa các Dự án Start-up của sinh viên với các nhà đầu tư. Chúng tôi cung cấp dịch vụ tư vấn phát triển kế hoạch, hỗ trợ giải quyết những rào cản, thách thức về nguồn vốn của một dự án Start-up.
              </span>
            </motion.p>
          </motion.div>
         </section>
        

        {/* S3 */}
       


        <section className={`${styles.paddings} relative z-10`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
    >
        <motion.div
        variants={fadeIn('left', 'tween', 0.2, 1)}
        className="flex-[0.75] flex justify-center flex-col "
      >
        <TypingText title="| How to use?" />
        <TitleText title={<>HAI BƯỚC ĐỂ THỰC HIỆN HÓA Ý TƯỞNG</>} textStyles="text-2xl md:text-4xl lg:text-4xl xl:text-6xl" />
        
      </motion.div>
      </motion.div>

      <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
    >
      <div className="w-full flex flex-col items-center justify-center mt-[3vw]">
         <TitleText title={<>Đăng ký <span className='text-green-500'> ➤ </span> Tạo dự án</>} textStyles="text-2xl md:text-2xl lg:text-3xl xl:text-4xl"  />
      
        </div>
        
     </motion.div>
     <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto `}
            style={{maxWidth: 'calc(100% - 15vw)'}}
          >
     <div className="mt-[5vw] flex flex-col items-start">
           <StepsComponent />
      </div>
      </motion.div>
      <motion.div
        variants={fadeIn('left', 'tween', 0.2, 1)}
        className="flex-[0.75] flex justify-center flex-col "
      >
      <div className="flex justify-end mx-20 p-7 "><img
              src={chatbotlogo}
              alt='Chatbot'
              className='sm:h-auto object-contain '
            /></div> 
            </motion.div>
      </section>
    
{/* S4 */}


</div>


{/* S5 */}

<div className="w-full bg-gray-900 py-[7vw] flex items-center ">
<div className="pl-[17vw] flex  justify-end ">
      <img
        src={chatbotlogov1}
        alt="Chatbot"
        className="sm:h-auto w-[55vw] object-contain"
      />
     
    </div>


  <div className="flex justify-end w-full pr-20">
    <div className="flex flex-col items-end space-y-4 p-4">
      <h2 className="text-white font-bold text-[3rem]">Sử dụng sức mạnh của AI để tăng năng suất</h2>
      <h2 className="text-white font-bold text-[3rem]">của công ty bạn</h2>
      <p className="text-gray-400 text-base sm:text-2xl">Cyno bot là chatbot thông minh được phát triển bởi team Cynosure với mục tiêu </p>
      <p className="text-gray-400 text-base sm:text-2xl">trở thành người bạn đồng hành hữu ích cho bạn trong hành trình khởi nghiệp và đầu tư. </p>

      <Button 
            text={'Thử ngay'} 
            textColor='text-black' 
            bgColor='gradient-01' 
            IcAfter={imarrow}
            onClick={goChat}
          />
    </div>
    
  </div>
</div>



<div className="bg-gray-900 py-[7vw] px-20 text-white">
      <div className="flex gap-5 justify-around">
        {categories.map(category => (
          <div key={category.id} className="text-center category-item">
            {category.icon}
            <h3 className="font-bold text-xl mb-2">{category.name}</h3>
            <p className="text-lg opacity-70">{category.description}</p>
          </div>
        ))}
      </div>
    </div>

  <h2 className="p-16 text-green-300 font-bold md:text-[6vw] sm:text-[5vw] xs:text-[4vw] text-[3vw]">Chào mừng bạn đến với Cyno bot!</h2>


<div className="p-2 mx-20 flex justify-center">
  <img
    src={chatbotlogov2}
    alt="Chatbot"
    className="sm:h-auto object-contain"
  />
</div>


<div className="slider-container hidden md:block">
      <div className="image-container">
        <img src={currentImage} alt="Slideshow Image" className="mx-56 my-16 w-[50vw] h-auto mb-4 hidden md:block rounded-2xl" />
                
            <div  className="features-container ml-auto text-right absolute top-1/2 transform -translate-y-1/2">
            <div className="feature mb-4">
                <FaBusinessTime className="feature-icon text-4xl text-purple-800" />
                <h3 className="feature-title text-lg font-bold text-gray-800">Thông tin về các bước khởi nghiệp</h3>
            </div>
            <div className="feature mb-4">
                <FaChartLine className="feature-icon text-4xl text-purple-800 mb-2" />
                <h3 className="feature-title text-lg font-bold text-gray-800 mb-2">Cập nhật xu hướng thị trường đầu tư</h3>
            </div>
            <div className="feature mb-4">
                <FaBuilding className="feature-icon text-4xl text-purple-800 mb-2" />
                <h3 className="feature-title text-lg font-bold text-gray-800 mb-2">Tìm kiếm thông tin về công ty khởi nghiệp</h3>
            </div>
            <div className="feature mb-4">
                <FaLightbulb className="feature-icon text-4xl text-purple-800 mb-2" />
                <h3 className="feature-title text-lg font-bold text-gray-800 mb-2">Cung cấp ý tưởng sáng tạo</h3>
            </div>
            <div className="feature mb-4">
                <FaCogs className="feature-icon text-4xl text-purple-800 mb-2" />
                <h3 className="feature-title text-lg font-bold text-gray-800 mb-2">Giới thiệu công cụ quản lý doanh nghiệp</h3>
            </div>
            </div>

        
      </div>
      <div className="dots-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentDotIndex === index ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    </div>





<StartupConsulting/>





















<div classname='m-auto'>
<BuildJourney/>

</div>

            <div classname='m-auto'>
<MailBox/>

</div>
<div className="m-auto">
           <Footer />
      </div>
 *






        </div>
        </>
    



   
  )
}

export default Home
