// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscribe-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 50px;
    background: white;
    border-radius: 20px;
    border: 4px solid white;
    padding: 5px;
  }
  
  .subscribe-input {
    width: calc(100% - 100px);
    height: 42.5px;
    line-height: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
  }
  
  .subscribe-input:focus {
    outline: none;
  }
  
  .subscribe-button {
    width: 100px;
    height: 42.5px;
    padding: 8px;
    font-size: 1em;
    color: #25cc7e;
    background: transparent;
    border: 1px solid #303030;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .subscribe-form:hover .subscribe-button {
    background: #05c7a0;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/mailbox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,cAAc;IACd,cAAc;IACd,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".subscribe-form {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 350px;\n    height: 50px;\n    background: white;\n    border-radius: 20px;\n    border: 4px solid white;\n    padding: 5px;\n  }\n  \n  .subscribe-input {\n    width: calc(100% - 100px);\n    height: 42.5px;\n    line-height: 30px;\n    outline: none;\n    border: none;\n    font-size: 1em;\n    border-radius: 20px;\n    padding: 0 20px;\n  }\n  \n  .subscribe-input:focus {\n    outline: none;\n  }\n  \n  .subscribe-button {\n    width: 100px;\n    height: 42.5px;\n    padding: 8px;\n    font-size: 1em;\n    color: #25cc7e;\n    background: transparent;\n    border: 1px solid #303030;\n    border-radius: 20px;\n    text-align: center;\n    cursor: pointer;\n  }\n  \n  .subscribe-form:hover .subscribe-button {\n    background: #05c7a0;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
