import React, { useCallback, useState, useEffect } from 'react';
import { Button } from "../../components";
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Menu, Drawer } from 'antd';
import { MenuOutlined, MonitorOutlined, UserOutlined, LogoutOutlined, LoginOutlined, UserAddOutlined } from "@ant-design/icons";
import CryptoJS from 'crypto-js';
import logo from "../../assets/logo.png";
import SearchBar from "./SearchBar";
import styles from '../../styles';
import { navVariants } from '../../utils/motion';
import "../../styles/main.css";
import { path } from "../../utils/constant"
import { Helmet } from 'react-helmet';

const API_URL = "https://cynosure.id.vn:5362";

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const goLogin = useCallback(() => {
    navigate(path.LOGIN);
  }, [navigate]);

  const goHome = useCallback(() => {
    navigate(path.HOME);
  }, [navigate]);
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  const goSignin = useCallback(() => {
    navigate(path.SIGNIN);
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }

    const userDataEncrypted = localStorage.getItem('userData');
    if (userDataEncrypted) {
      try {
        const bytes = CryptoJS.AES.decrypt(userDataEncrypted, 'your-secret-key');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserInfo(decryptedData);
      } catch (error) {
        console.error('Lỗi khi giải mã hoặc phân tích JSON từ dữ liệu người dùng:', error);
      }
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/');
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const menuItems = [
    { label: <Link to="/">Trang chủ</Link>, key: 'home' },
    { label: <Link to="/aboutus">Về chúng tôi</Link>, key: 'aboutus' },
    { label: <Link to="/chat">Chatbot khởi nghiệp</Link>, key: 'chat' },
    { label: <Link to="/premium">Ưu đãi</Link>, key: 'premium' },
    { label: <Link to="/project">Dự án</Link>, key: 'project' },
  ];

  return (
    <motion.nav
      variants={navVariants}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.xPaddings} py-8 relative`}
    >
      <nav className="nav">
        <a className='flex items-center justify-between absolute top-0 left-0'>
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="w-[15vw] h-[4vw] mx-10 my-7 object-cover"
            />
          </Link>
        </a>

        {/* Kiểm tra kích thước màn hình */}
        {window.innerWidth <= 768 ? ( 
          // Mobile
          <div className="menu__container">
            <button
              type="primary"
              onClick={showDrawer}
              className="menu__button"
            ><MenuOutlined /></button>

            <Drawer
              title="Menu"
              placement="right"
              onClose={onClose}
              visible={drawerVisible}
            >
              <Menu items={menuItems} />
            </Drawer>
          </div>
        ) : (
          // Desktop
          <ul className="nav__menu">
            <li className="nav__item">
              <Link to="/" className="nav__link">
                Trang chủ
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/aboutus" className="nav__link">
                Về chúng tôi
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/chat" className="nav__link">
                Chatbot khởi nghiệp
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/premium" className="nav__link">
                Ưu đãi
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/project" className="nav__link">
                Dự án
              </Link>
            </li>
            <a className='flex gap-2 my-7 object-cover justify-end'>

              <li className="nav__item">
                {isLoggedIn ? (
                  <div className="relative dropdown-container">
                    <button onClick={toggleDropdown} className="w-12 h-12 bg-gray-500 rounded-full flex items-center justify-center">
                      <span className="text-white">👤</span>
                    </button>
                    {showDropdown && (
                      <div className="absolute right-0 mt-4 w-[30vw] bg-white rounded-md shadow-lg z-10">
                        <ul className="py-1">
                          <li>
                            {userInfo && (
                              <div className="text-black text-2xl p-4">
                                <p>Xin chào, {userInfo.fullName}!</p>
                                <p>Email: {userInfo.email}</p>
                              </div>
                            )}
                            <hr />
                            <button onClick={handleLogout} className="block px-4 py-2 text-3xl text-red-500 font-bold hover:bg-gray-100">
                              Đăng xuất
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="flex">
                      <a href="/login" className="nav__link">
                        <Button text="Đăng nhập" textColor="text-white" />
                      </a>
                      <a href="/signup" className="nav__link">
                        <Button text="Đăng ký" textColor="text-black" bgColor="bg-[#a3e635]" />
                      </a>
                    </div>
                  </>
                )}
              </li>
            </a>
          </ul>
        )}
        
        {/* Code hàm kiểm tra desktop ở đây */}
        
      </nav>
      {/* <Helmet>
      <script id="facebook-pixel">
      {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '819848312961179');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=819848312961179&ev=PageView&noscript=1"
/>`}
    </script>
    </Helmet> */}
    </motion.nav>
    
  );
};

export default Navbar;