import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';
import logo from '../../assets/logo.png';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = "https://cynosure.id.vn:5362"; // Thay thế bằng URL API backend của bạn
const SECRET_KEY = 'your-secret-key';

const AdminLogin = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [chucvu, setChucvu] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
  const [userData, setuserData] = useState(null); // State để lưu thông tin người dùng đã đăng nhập
  const navigate = useNavigate();

  useEffect(() => {
    // Giải mã và lấy dữ liệu người dùng từ localStorage khi component được mount
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const bytes = CryptoJS.AES.decrypt(storedUserData, SECRET_KEY);
        const userDataString = bytes.toString(CryptoJS.enc.Utf8);
        const user = JSON.parse(userDataString);
        setuserData(user);
      } catch (error) {
        console.error("Lỗi giải mã dữ liệu người dùng từ localStorage:", error);
      }
    }
  }, []);

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    setLoadingAdmin(true);

    try {
      const response = await axios.post(`${API_URL}/login_admin`, { email, password });

      if (response.data && response.data.success) {
        const userData = {
          id: response.data.data.id,
          fullName: response.data.data.fullName,
          email: response.data.data.email,
          chucvu: response.data.data.chucvu,
        };

        // Mã hóa thông tin người dùng trước khi lưu vào localStorage
        const userDataString = JSON.stringify(userData);
        const encrypteduserData = CryptoJS.AES.encrypt(userDataString, SECRET_KEY).toString();
        localStorage.setItem('userData', encrypteduserData);
        localStorage.setItem('chucvu', response.data.data.chucvu);
	      localStorage.setItem('id',response.data.data.id);
        setuserData(userData); // Lưu thông tin người dùng vào state
        navigate("/admin"); // Điều hướng đến trang admin sau khi đăng nhập thành công
      } else {
        setErrorMessage(response.data.message || "Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin tài khoản của bạn.");
        setLoadingAdmin(false);
      }
    } catch (error) {
      console.error("Lỗi khi đăng nhập:", error);
    
      setErrorMessage("Đăng nhập thất bại. Vui lòng thử lại.");
    }finally {
      setLoadingAdmin(false);
    }
  };

  return (
    <div>
      <HeaderAdmin />
      <div className="flex h-screen items-center">
        <div className="w-1/2 flex items-end justify-end">
          <img src={logo} alt="Logo" className="w-34 h-34 object-contain" />
        </div>
        <div className="w-1/2 flex items-center justify-center">
          <div className="boxed bg-gray-200 p-10 rounded-3xl shadow-lg w-[400px] h-120 flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-gray-100 mb-4">Đăng nhập Admin</h2>
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            <form onSubmit={handleAdminLogin}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-md text-gray-100 font-medium">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded-xl"
                  placeholder="Nhập email của bạn"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-md text-gray-100 font-medium">Mật khẩu</label>
                <input
                  type="password"
                  id="password"
                  className="w-full p-2 border rounded-xl"
                  placeholder="Nhập mật khẩu của bạn"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                className="w-full gradient-01 text-white p-2 rounded-xl mb-4"
                type="submit"
                disabled={loadingAdmin}
              >
                {loadingAdmin ? 'Đang đăng nhập...' : 'Đăng nhập'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
