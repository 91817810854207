import { Routes, Route, useNavigate } from "react-router-dom"; // Nhập useNavigate
import { Header, Home, Login, Signin, Aboutus, ChatBot, Project, Premium, ForgotPass, ResetPass, ErrorPage,Gemini, Payment, PrivacyPolicy,TermsAndConditions } from "./container/public";
import Admin from "./container/system/Admin";
import AdminLogin from "./container/system/AdminLogin";
import { path } from "./utils/constant";
import React, { useState, useEffect } from 'react';
import AdminProtectedRoute from './container/system/AdminProtectedRoute';
import RegisterDetails from "./container/public/RegesterDetail";
import { AuthProvider } from './context/AuthContext';

function App() {
  const navigate = useNavigate(); // Sử dụng useNavigate
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Trạng thái đăng nhập
  const [userName, setUserName] = useState(""); // Tên người dùng
  const [chucvu, setChucvu] = useState(""); // Tên người dùng
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(false); // Trạng thái đăng nhập

  useEffect(() => {
    const token = localStorage.getItem("token");
    const chucvu = localStorage.getItem("chucvu");
    if (token) {
      setIsLoggedIn(true);
      setUserName(localStorage.getItem("userName")); // Lấy tên người dùng từ localStorage
    }
    if (chucvu){
      setAdminLoggedIn(true);
      setChucvu(localStorage.getItem("chucvu")); // Lấy tên người dùng từ localStorage
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("chucvu");
    setIsLoggedIn(false);
    setAdminLoggedIn(false);
    setUserName("");
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  const handleSignInRedirect = () => {
    navigate("/signin");
  };

  return (
    
    <div className="width=device-width initial-scale=1 hero-gradient">
      <div>
        <Routes>
        <Route path={path.HOME} element={<Home />} />
          <Route path={path.PREMIUM} element={<Premium />} />
          <Route path={path.ABTUS} element={<Aboutus />} />
          <Route path={path.PROJECT} element={<Project />} />
          <Route path={path.CHATBOT} element={<ChatBot />} /> {/* Trang không cần header */}
          <Route path={path.LOGIN} element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path={path.SIGNIN} element={<Signin setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/register/:type/details" element={<RegisterDetails />} />
          <Route path={path.ADMIN} element={<Admin />} />
          <Route path={path.FORGOT} element={<ForgotPass />} />
          <Route path={path.RESET} element={<ResetPass />} />
          <Route path={path.ERROR} element={<ErrorPage />} />
          <Route path={path.GEMINI} element={<Gemini />} />
          <Route path="/payment/:packageId" element={<Payment />} />
          <Route path={path.POLY} element={<PrivacyPolicy />} />
          <Route path={path.DIEUKHOAN} element={<TermsAndConditions />} />

          <Route path="*" element={<ErrorPage />} />
        
          <Route path="/admin/login" element={<AdminLogin setAdminLoggedIn={setAdminLoggedIn} />} />
          <Route path="/admin" element={
              <Admin />
          } />
          <Route path={path.ERROR} element={<ErrorPage />} />
          <Route path={path.GEMINI} element={<Gemini />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
