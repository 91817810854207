// Import cần thiết từ React và các thư viện khác
import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../utils/motion';
import { Link } from 'react-router-dom'; // Thêm Link từ react-router-dom
import './StartupConsulting.css'; // CSS cho trang này
import { FaBuilding, FaUserTie, FaUsers } from 'react-icons/fa';
import pbt from "../assets/pbt.svg"
import lhb from "../assets/lhb.svg"
import lbc from "../assets/lbc.svg"

// Dữ liệu về tư vấn viên và tổ chức
const advisors = [
  {
    name: "Phạm Bửu Tài",
    role: "Chuyên gia tư vấn khởi nghiệp",
    bio: "Phạm Bửu Tài có nhiều năm kinh nghiệm trong việc hỗ trợ các doanh nghiệp khởi nghiệp đạt được thành công. Ông đã tư vấn cho nhiều doanh nghiệp về chiến lược kinh doanh, phát triển sản phẩm và tiếp thị.",
    image: pbt, // Đường dẫn đến ảnh
  },
  {
    name: "Lư Ngọc Chi",
    role: "Chuyên gia phát triển doanh nghiệp",
    bio: "Lư Ngọc Chi chuyên về quản trị doanh nghiệp và phát triển chiến lược. Bà đã hỗ trợ nhiều doanh nghiệp xây dựng kế hoạch kinh doanh và tìm kiếm nguồn tài trợ.",
    image: lbc, // Đường dẫn đến ảnh
  },
  {
    name: "Lê Hoàng Bảo",
    role: "Chuyên gia tài chính",
    bio: "Lê Hoàng Bảo có nhiều kinh nghiệm trong việc quản lý tài chính và đầu tư. Ông giúp đỡ các doanh nghiệp khởi nghiệp quản lý dòng tiền và tìm kiếm nguồn vốn phù hợp.",
    image: lhb, // Đường dẫn đến ảnh
  },
];

const organizations = [
  {
    name: "ADSMO",
    description: "là một thương hiệu của Công ty TNHH UNIKERY. Chuyên viên tư vấn khởi nghiệp là những người có chuyên môn và kinh nghiệm thực tế trên nhiều lĩnh vực ngành nghề để tư vấn cho doanh nghiệp khởi nghiệp những gì cần làm để giúp doanh nghiệp của bạn đi vào hoạt động ổn định, tiết kiệm tiền bạc, thời gian và quản trị được những rủi ro khủng hoảng",
    icon: <FaBuilding />, // Icon cho công ty
  },
  {
    name: "Cổng hỗ trợ khởi nghiệp Việt Nam",
    description: "Là 1 mô hình hỗ trợ khởi nghiệp toàn diện, đa dạng và chuyên nghiệp nhất Việt Nam hiện nay. Tất cả các hỗ trợ đều mang tính thực tế cao và phù hợp với mọi lĩnh vực, hoàn cảnh, quy mô của từng đối tượng và dự án khởi nghiệp, mang tinh thần và sứ mệnh khởi nghiệp Quốc gia. ",
    icon: <FaUsers />, // Icon cho hiệp hội
  },
  {
    name: "EDCON",
    description: "Công ty TNHH Tư vấn Phát triển Doanh nghiệp (EDCON) hoạt động chuyên nghiệp trong lĩnh vực tư vấn khởi nghiệp, tư vấn đầu tư phát triển dự án, cung cấp dịch vụ tư vấn pháp luật liên quan đến các hoạt động khởi nghiệp, đầu tư mở rộng và phát triển kinh doanh cho các doanh nghiệp, các nhà đầu tư trong nước và các nhà đầu tư nước ngoài",
    icon: <FaUserTie />, // Icon cho công ty tư vấn
  },
  // Thêm các tổ chức khác ở đây
];

const StartupConsulting = () => {
  return (
    <div className="startup-consulting-container">
      <motion.div variants={fadeIn('up', 'tween', 0.3, 0.5)} className="intro-section">
        <hr/>
        <h1>Dịch Vụ Tư Vấn Khởi Nghiệp</h1>
        <p className="text-2lg">
          Chúng tôi cung cấp dịch vụ tư vấn khởi nghiệp chuyên nghiệp với đội ngũ tư vấn viên giàu kinh nghiệm và các tổ chức hỗ trợ doanh nghiệp hàng đầu. Hãy tìm hiểu thêm về đội ngũ tư vấn viên và các tổ chức hỗ trợ của chúng tôi dưới đây.
        </p>
      </motion.div>

      <motion.div variants={fadeIn('up', 'tween', 0.5, 0.5)} className="team-section">
        <h2 className="text-3xl text-gray-200 font-bold">Đội Ngũ Tư Vấn</h2>
        <div className="team-container">
          {advisors.map((advisor, index) => (
            <div key={index} className="advisor-card">
              <img src={advisor.image} alt={advisor.name} className="advisor-image" />
              <h3 className="font-bold">{advisor.name}</h3>
              <p>{advisor.role}</p>
              <p>{advisor.bio}</p>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div variants={fadeIn('up', 'tween', 0.7, 0.5)} className="organizations-section">
        <h2 className="text-3xl text-gray-200 font-bold">Các Tổ Chức Chuyên Tư Vấn Khởi Nghiệp</h2>
        <div className="organizations-container">
          {organizations.map((organization, index) => (
            <div key={index} className="organization-card">
              <div className="organization-icon">{organization.icon}</div>
              <h3 className="font-bold">{organization.name}</h3>
              <p>{organization.description}</p>
            </div>
          ))}
        </div>
      </motion.div>

      <div className="footer">
        <hr />
      </div>
    </div>
  );
};

export default StartupConsulting;
