import React, { useRef } from 'react'
import styles from '../../styles'
import rocket from '../../assets/rocket.svg';
import { Button } from "../../components"
import imarrow from "../../utils/arrow"
import Header from "../public/Header"
import Footer from "../../components/Footer"
import pre1 from '../../assets/pre-1.svg';
import pre2 from '../../assets/pre-2.svg';
import pre3 from '../../assets/pre-3.svg';
import "./Premium.css"
const Premium = () => {
  const pricingRef = useRef(null); // Tham chiếu đến phần "Price"

  const handleClick = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onGoi1 = () => {
    // Chuyển hướng đến link "/payment/001"
    window.location.href = '/payment/001'; 
  };
  const onGoi2 = () => {
    // Chuyển hướng đến link "/payment/001"
    window.location.href = '/payment/002'; 
  };
  const onGoi3 = () => {
    // Chuyển hướng đến link "/payment/001"
    window.location.href = '/payment/003'; 
  };
  return (
    <div className='flex-col'>  
    <Header/>
      
        <div className='flex '>
        <section className={`${styles.yPaddings} w-1/2 sm:pl-16 pl-16`}>
            <div className="mx-5">
            <span className={`${styles.heroDTextv2} text-white`}>KHỞI NGHIỆP DỄ DÀNG HƠN VỚI </span><span className={`${styles.heroDTextv2} text-lime-300`}>GÓI PREMIUM</span> 
            </div>
            <div className="mx-5">

            <span className={`${styles.herosText1}`}>Bước đầu khởi nghiệp thành công cùng Cynosure.</span>
            <Button 
              text={'Mua premium'} 
              textColor='text-black' 
              bgColor='gradient-01' 
              IcAfter={imarrow}
              onClick={handleClick}
              />
                  </div>

    </section>
    <section className='w-1/2 flex items-center justify-end pr-14'>
    <img
              src={rocket}
              alt='Rocket'
              className='w-[495px] h-[495px] object-contain'
            />

            
    </section>
    </div>

    <div className="w-full bg-gray-900 py-[70px] flex items-center ">
<div className="pl-[110px] flex  justify-end ">
      <img
        src={pre1}
        alt="Chatbot"
        className="sm:h-auto w-[800px] object-contain"
      />

    </div>
    <div className="flex justify-end w-full pr-20">
    <div className="flex flex-col items-end space-y-4 p-4">
      <h2 className="text-white font-bold text-[3rem]">Gói Premium cho</h2>
      <h2 className="text-lime-200 font-bold text-[3rem]">Speech To Text</h2>
      <p className="text-gray-400 text-base sm:text-2xl">Chuyển đổi giọng nói thành văn bản</p>
      <p className="text-gray-400 text-base sm:text-2xl pl-80 text-justify"> Gói dịch vụ cơ bản Speech To Text là công cụ đơn giản và hiệu quả giúp chuyển đổi giọng nói tiếng Việt thành văn bản một cách nhanh chóng và chính xác. Với khả năng nhận diện giọng nói tự nhiên và xử lý âm thanh, gói dịch vụ này lý tưởng cho các cuộc họp, ghi chú hàng ngày và tạo tài liệu một cách dễ dàng. Đây là giải pháp đáng tin cậy để nâng cao hiệu quả làm việc và tổ chức thông tin trong môi trường ngôn ngữ tiếng Việt.</p>
      <button className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2" onClick={onGoi1}>Chọn gói này</button>
    </div>

    </div>

    </div>

{/* Hinh2 */}

    <div className="w-full py-[70px] flex items-center ">

    <div className="pl-[100px] flex pr-[7px] justify-center flex-col items-start p-4">
      <h2 className="text-white font-bold text-[3rem]">Gói Premium cho RAG </h2>
      <h2 className="text-lime-200 font-bold text-[3rem]">Retrieval-Augmented Generation</h2>
      <p className="text-gray-400 text-base sm:text-2xl">Tự động hóa truy xuất thông tin </p>
      <p className="text-gray-400 text-base sm:text-2xl pr-40 text-justify">Gói dịch vụ Nâng Cao RAG sử dụng công nghệ tiên tiến để tự động hóa quá trình truy xuất thông tin từ các nguồn dữ liệu khổng lồ. RAG kết hợp khả năng của các mô hình học sâu và các công cụ tìm kiếm để cung cấp câu trả lời chính xác và chi tiết. Đây là giải pháp hiệu quả cho việc nghiên cứu, phân tích dữ liệu và tạo nội dung có giá trị trong lĩnh vực tiếng Việt.</p>
      <button className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2" onClick={onGoi2}>Chọn gói này</button>
    </div>
<div className="w-1/2 flex justify-end">
    <div className="pr-20 flex justify-end ">
      <img
        src={pre2}
        alt="Chatbot"
        className="sm:h-auto w-[400px] object-contain"
      />
    
    </div>
    </div>


    </div>


{/* Hinh 3 */}

<div className="w-full py-[70px] bg-gray-900 flex items-center ">
<div className="pl-[110px] flex  justify-end ">
      <img
        src={pre3}
        alt="Chatbot"
        className="sm:h-auto w-[800px] object-contain"
      />

    </div>
    <div className="flex justify-end w-full pr-20">
    <div className="flex flex-col items-end space-y-4 p-4">
      <h2 className="text-white font-bold text-[3rem]">Gói Chuyên Nghiệp RAG</h2>
      <h2 className="text-lime-200 font-bold text-[3rem]">Retrieval-Augmented Generation - Chuyên Nghiệp</h2>
      <p className="text-gray-400 text-base text-justify sm:text-2xl">Phân tích và tạo nội dung thông minh</p>
      <p className="text-gray-400 text-base sm:text-2xl pl-80 text-justify">Gói dịch vụ Chuyên Nghiệp RAG là công cụ đắc lực cho việc phân tích dữ liệu phức tạp và tạo ra nội dung thông minh, chất lượng cao. RAG giúp tổ chức xử lý và đánh giá dữ liệu từ nhiều nguồn khác nhau, đáp ứng nhu cầu tạo báo cáo chi tiết, phân tích thị trường và tối ưu hóa chiến lược kinh doanh bằng tiếng Việt.</p>
      <button className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2" onClick={handleClick}>Coming Soon</button>
    </div>

    </div>

    </div>

{/* Price */}
<div ref={pricingRef} className="pricing-container">
<div className="pricing-container">
      <h1 className="pricing-title">Pricing Plans</h1>
      <div className="pricing-cards">
        <div className="pricing-card">
          <h2 className="pricing-plan">Gói Speech-to-text</h2>
          <p className="pricing-price">129.000/tháng</p>
          <ul className="pricing-features">
            <li>Chuyển đổi giọng nói thành văn bản</li>
            <li>Giọng nói tự nhiên</li>
            <li>Xử lý âm thanh</li>
            <li>Nâng cấp khả năng chatbot</li>
          </ul>
          <button className="pricing-button" onClick={onGoi1}>Chọn gói này</button>
        </div>
        <div className="pricing-card">
          <h2 className="pricing-plan">Gói RAG</h2>
          <p className="pricing-price">150.000/tháng</p>
          <ul className="pricing-features">
            <li>Tự động hóa truy xuất thông tin</li>
            <li>Công nghệ tiên tiến</li>
            <li>Mô hình học sâu</li>
            <li>Nghiên cứu dữ liệu</li>
            <li>Nâng cấp khả năng chatbot</li>
          </ul>
          <button className="pricing-button" onClick={onGoi2}>Chọn gói này</button>
        </div>
        <div className="pricing-card">
          <h2 className="pricing-plan">Gói RAG Chuyên Nghiệp</h2>
          <p className="pricing-price">Coming Soon</p>
          <ul className="pricing-features">
            <li>Phân tích dữ liệu phức tạp</li>
            <li>Báo cáo chi tiết</li>
            <li>Lưu trữ dữ liệu</li>
            <li>Cá nhân hóa chatbot</li>
          </ul>
          <button className="pricing-button" onClick={handleClick}>Coming Soon</button>
        </div>
      </div>
    </div>
    </div>



    <div ref={pricingRef} className="pricing-container">
<div className="pricing-container">
      <div className="pricing-cards">
        <div className="pricing-card">
          <h2 className="pricing-plan">Tư vấn khởi nghiệp</h2>
          <p className="pricing-price">Liên hệ</p>
          <ul className="pricing-features">
            <li>Ý tưởng sáng tạo
            </li>
            <li>Chiến lược kinh doanh
            </li>
            <li>Thị trường tiềm năng
            </li>
          </ul>
          <button className="pricing-button" onClick={() => window.location.href = "tel:0328 770 383"}>Liên hệ ngay</button>
        </div>
        <div className="pricing-card">
          <h2 className="pricing-plan">Tư vấn lập kế hoạch đầu tư</h2>
          <p className="pricing-price">Liên hệ</p>
          <ul className="pricing-features">
            <li>Tầm nhìn tài chính
            </li>
            <li>Phân tích rủi ro
            </li>
            <li>Đầu tư bền vững
            </li>
          </ul>
          <button className="pricing-button" onClick={() => window.location.href = "tel:0328 770 383"}>Liên hệ ngay</button>
        </div>
        <div className="pricing-card">
          <h2 className="pricing-plan">Tư vấn quản lý dự án</h2>
          <p className="pricing-price">Liên hệ</p>
          <ul className="pricing-features">
            <li>Chiến lược đầu tư
            </li>
            <li>Báo cáo chi tiết</li>
            <li>Quản lý rủi ro
            </li>

          </ul>
          <button className="pricing-button" onClick={() => window.location.href = "tel:0328 770 383"}>Liên hệ ngay</button>
        </div>
      </div>
    </div>
    </div>






















    <div className="m-auto mt-[50px]">
           <Footer />
      </div>
    </div>

  )
}

export default Premium
