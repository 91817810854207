import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from "../system/HeaderAdmin";
import './ForgotPassword.css'; // Import file CSS để tùy chỉnh giao diện

function ForgotPass() {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const navigate = useNavigate(); // Sử dụng useNavigate để chuyển hướng

    const sendVerificationCode = async () => {
        if (!email) {
            setMessage("Vui lòng nhập email trước khi gửi mã xác nhận.");
            return;
        }

        try {
            console.log('Email được gửi tới backend:', { email }); // In ra email trước khi gửi request
            const response = await fetch('http://cynosure.id.vn:5362/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const result = await response.json();
            if (result.error) {
                setMessage("Không tồn tại tài khoản với email này.");
            } else {
                setStep(2);
                setMessage("Mã xác nhận của bạn đã được gửi đến email.");
            }
        } catch (error) {
            console.error("Error sending verification code:", error);
            setMessage("Đã xảy ra lỗi khi gửi mã xác nhận.");
        }
    };
    
    const verifyCode = async () => {
        try {
            const response = await fetch("http://cynosure.id.vn:5362/verify-code", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, verificationCode }),
            });
            const result = await response.json();
            if (result.error) {
                setMessage("Mã xác nhận không chính xác.");
            } else {
                setStep(3);
                setMessage("");
            }
        } catch (error) {
            console.error("Error verifying code:", error);
            setMessage("Đã xảy ra lỗi khi xác nhận mã.");
        }
    };

    const resetPassword = async () => {
        if (newPassword.length < 8 ) {
            setMessage("Mật khẩu phải có ít nhất 8 ký tự.");
            return;
        }

        if (newPassword !== confirmPassword) {
            setMessage("Mật khẩu không khớp.");
            return;
        }

        try {
            const response = await fetch('http://cynosure.id.vn:5362/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, newPassword }),
            });
            const result = await response.json();
            if (result.error) {
                setMessage("Đã xảy ra lỗi khi đặt lại mật khẩu.");
            } else {
                setMessage("Mật khẩu đã được đặt lại thành công.");
                navigate('/login'); // Chuyển hướng đến trang login
            }
        } catch (error) {
            console.error("Error resetting password:", error);
            setMessage("Đã xảy ra lỗi khi đặt lại mật khẩu.");
        }
    };

    return (
        <div className="center-container">
            <HeaderAdmin />
            <div className="box">
                <h2 className="forgot-password-header">Quên mật khẩu</h2>
                {step === 1 && (
                    <div>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Nhập email của bạn" required />
                        <button onClick={sendVerificationCode}>Gửi mã xác nhận</button>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Nhập mã xác nhận" required />
                        <button onClick={verifyCode}>Xác nhận mã</button>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Nhập mật khẩu mới" required />
                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Xác nhận mật khẩu" required />
                        <button onClick={resetPassword}>Đặt mật khẩu mới</button>
                    </div>
                )}
                <p>{message}</p>
            </div>
        </div>
    );
}

export default ForgotPass;