import React from 'react';
import { useLocation } from 'react-router-dom';

const RegisterDetails = () => {
  const location = useLocation();
  const user = location.state;

  return (
    <div>
      {/* Header của trang chi tiết */}
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Thông tin chi tiết</h1>
        <p>Email: {user.email}</p>
        <p>Password: {user.password}</p> 
        <p>Loại tài khoản: {user.selectedOption}</p> 
        <p>Họ và tên: {user.fullName}</p> 
        <p>Ngày sinh: {user.dateOfBirth}</p> 
        {/* ... Các nội dung khác trong form chi tiết */}
      </div>
    </div>
  );
};

export default RegisterDetails;