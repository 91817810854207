// Login.js
import React, { useState, useEffect } from 'react';
import { Button } from "../../components";
import HeaderAdmin from "../system/HeaderAdmin";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom'; // Thay thế useHistory bằng useNavigate
import logo from '../../assets/logo.png';

const API_URL = "https://cynosure.id.vn:5362"; // Thay thế bằng URL API backend của bạn
const SECRET_KEY = 'your-secret-key';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); // Khởi tạo useNavigate

  useEffect(() => {
    // Giải mã và lấy dữ liệu người dùng từ localStorage khi component được mount
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const bytes = CryptoJS.AES.decrypt(storedUserData, SECRET_KEY);
        const userDataString = bytes.toString(CryptoJS.enc.Utf8);
        const user = JSON.parse(userDataString);
        setUserData(user);
      } catch (error) {
        console.error("Lỗi giải mã dữ liệu người dùng từ localStorage:", error);
      }
    }
  }, []);

  const goSignin = () => navigate("/signup"); // Sử dụng navigate
  const goForgot = () => navigate("/forgot-password"); // Sử dụng navigate

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });

      if (response.data.success) {
        const userData = {
          id: response.data.data.id,
          fullName: response.data.data.fullName,
          email: response.data.data.email,
          goi001: response.data.data["001"], // Access property using bracket notation
          goi002: response.data.data["002"], // Similarly for other properties
          goi003: response.data.data["003"]
        };
        console.log("cc",response.data.success)
        // Mã hóa thông tin người dùng trước khi lưu vào localStorage
        const userDataString = JSON.stringify(userData);
        const encryptedUserData = CryptoJS.AES.encrypt(userDataString, SECRET_KEY).toString();
        localStorage.setItem('userData', encryptedUserData);
        localStorage.setItem('token', response.data.token);

        setUserData(userData);
        navigate("/"); // Chuyển hướng đến trang hiển thị thông tin người dùng
      } else {
        setErrorMessage(response.data.message || "Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin tài khoản của bạn.");
      }
    } catch (error) {
      console.error("Lỗi khi đăng nhập:", error);
      setErrorMessage("Đăng nhập thất bại. Vui lòng thử lại.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
    <div className="absolute top 0">
      <HeaderAdmin />
      </div>
      <div className="flex h-screen items-center">
        <div className="w-1/2 flex items-end justify-end">
          <img src={logo} alt="Logo" className="w-34 h-34 object-contain" />
        </div>
        <div className="w-1/2 flex items-center justify-center">
          <div className="boxed bg-gray-200 p-10 rounded-3xl shadow-lg w-[400px] h-120 flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-gray-100 mb-4">Đăng nhập</h2>
            <span className="flex items-center text-gray-100 text-2xl">
              Người dùng mới?{' '}
              <Button
                text="Đăng ký ngay"
                textColor="text-blue-500"
                onClick={goSignin}
              />
            </span>
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-md text-gray-100 font-medium">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded-xl"
                  placeholder="Nhập email của bạn"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-md text-gray-100 font-medium">Mật khẩu</label>
                <input
                  type="password"
                  id="password"
                  className="w-full p-2 border rounded-xl"
                  placeholder="Nhập mật khẩu của bạn"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                className="w-full gradient-01 text-white p-2 rounded-xl mb-4"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Đang đăng nhập...' : 'Đăng nhập'}
              </button>
            </form>
            <div className="flex justify-between text-sm">
              <Button
                text="Quên mật khẩu?"
                textColor="text-blue-500"
                onClick={goForgot}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
