import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../utils/motion';
import logo from "../assets/logo-01.png"
import { Link } from 'react-router-dom'; // Thêm Link từ react-router-dom
import { FaFacebook, FaTiktok } from 'react-icons/fa';

const ContactInfoComponent = () => {
  return (
    <div className="w-full">
     <div div className="flex items-center justify-between">
      <motion.div variants={fadeIn('up', 'tween', 1.2, 0.5)} className="logo-container">
      <img
            src={logo}
            alt="logo"
            className="w-[200px] h-[80px] mx-10 my- object-cover"
        />
      </motion.div>
      <div className="contact-info text-green-300 flex justify-end mx-20">
        <motion.div variants={fadeIn('up', 'tween', 1.2, 0.5)} className="contact-item">
          <p>Email: cynosurestartup.co.ltd@gmail.com</p>
        </motion.div>
        
        <motion.div variants={fadeIn('up', 'tween', 1.2, 0.5)} className="contact-item">
          <p>Phone: 0328 770 383 (Văn Văn)</p>

        </motion.div>
        <div className="flex ml-4 gap-8">
            <Link to="https://www.facebook.com/profile.php?id=61559616340803" className="text-white" target="_blank">
              <FaFacebook className="text-white text-3xl" />
            </Link>
            <Link to="https://www.tiktok.com/@cynosure2401?_t=8mcSk0tEUKu&_r=1&fbclid=IwZXh0bgNhZW0CMTAAAR2KGpdlZR5F2FVfX4iY86z66QPGsgk-9Bu0wQf-s60yWDaiMRIuHxQDmVw_aem_3Zx0hiftzFd04Rzyqrca0g" className="text-white" target="_blank">
              <FaTiktok className="text-white text-3xl" />
            </Link>
          </div>
        </div>
        <div className="contact-info text-green-300 flex justify-end mx-20">
        <motion.div variants={fadeIn('up', 'tween', 1.2, 0.5)} className="contact-info text-green-300 flex flex-col items-end">
          <Link to="/chinh-sach">
            <button className="mb-3 p-3 text-white rounded hover:bg-blue-600 transition">CHÍNH SÁCH BẢO MẬT THÔNG TIN</button>
          </Link>
          <Link to="/dieu-khoan">
            <button className="p-3  text-white rounded hover:bg-green-600 transition">ĐIỀU KHOẢN VÀ ĐIỀU KIỆN SỬ DỤNG</button>
          </Link>
        </motion.div>

        </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
    <hr style={{ width: '50%' }}/>
    <p className="text-green-500 ">Copyright © 2023 | Cynosure</p>

</div>

  </div>
      
      
  );
};

export default ContactInfoComponent;
