import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';
import { Button } from "../components";
import imarrow from "../utils/arrow";

const BuildJourney = () => {
  const navigate = useNavigate();

  const handleStartupClick = () => {
    navigate('/signup', { state: { accountType: 'startup' } });
  };

  const handleInvestorClick = () => {
    navigate('/signup', { state: { accountType: 'investor' } });
  };

  return (
    <div className={`${styles.heroHeading1} text-center mt-[50px]`}>
      Cùng <span className="text-green-500">Cynosure</span> xây dựng hành trình lớn!
      <p className="text-3xl text-center text-green-500">Bạn là...</p>
      <div className="flex justify-center mt-4">
        <div className="mr-4">
          <Button
            text="Start - up"
            textColor="text-gray-500"
            bgColor="black"
            onClick={handleStartupClick}
          />
        </div>
        <div className="ml-2">
          <Button
            text="Nhà đầu tư"
            textColor="text-black"
            bgColor="white"
            onClick={handleInvestorClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BuildJourney;
