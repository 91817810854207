import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './mailbox.css'; // Liên kết file CSS cho thanh nhập email

const SubscribeBar = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/signup', { state: { email } });
  };

  return (
    <div className="subscribe-container flex items-center justify-center mt-[50px]">
      <form onSubmit={handleSubmit} className="subscribe-form">
        <input
          type="email"
          placeholder="Nhập email của bạn"
          value={email}
          onChange={handleChange}
          className="subscribe-input"
          required
        />
        <button type="submit" className="subscribe-button">
          Đăng ký
        </button>
      </form>
    </div>
  );
};

export default SubscribeBar;
