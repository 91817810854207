import React from 'react'
import HeaderAdmin from "../system/HeaderAdmin";
import errorimg from "../../assets/errorimg.svg"


const PROJECT_PATH = "/project";

  
const Project = () => 
    {
        const goProject = () => {
    window.location.href = PROJECT_PATH;
  };
  return (
        <div className="flex-col m-auto " style={{ backgroundColor: "#f0f0f0" }}>        

    <div className="flex justify-end mx-20 h-screen"><img
              src={errorimg}
              alt='Chatbot'
              className='sm:h-auto object-contain '
            />
    </div>
    </div>
  )
}

export default Project
