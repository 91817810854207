// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pricing.css */
.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #fdfdfd
  }
  
  .pricing-title {
    font-size: 3.5rem;
    margin-bottom: 20px;
    font-weight: bolder;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .pricing-card {
    border: 1px solid #0056b3;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
  }
  
  .pricing-plan {
    color:#453288;
    font-size: 3rem;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .pricing-price {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .pricing-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .pricing-features li {
    margin-bottom: 10px;
  }
  
  .pricing-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .pricing-button:hover {
    background-color: #4ee36c;
  }
  `, "",{"version":3,"sources":["webpack://./src/container/public/Premium.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb;EACF;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Pricing.css */\n.pricing-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    background: #fdfdfd\n  }\n  \n  .pricing-title {\n    font-size: 3.5rem;\n    margin-bottom: 20px;\n    font-weight: bolder;\n  }\n  \n  .pricing-cards {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n  }\n  \n  .pricing-card {\n    border: 1px solid #0056b3;\n    padding: 20px;\n    width: 300px;\n    border-radius: 10px;\n    text-align: center;\n  }\n  \n  .pricing-plan {\n    color:#453288;\n    font-size: 3rem;\n    margin-bottom: 10px;\n    font-weight: bold;\n  }\n  \n  .pricing-price {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n    font-weight: 600;\n  }\n  \n  .pricing-features {\n    list-style-type: none;\n    padding: 0;\n    margin-bottom: 20px;\n  }\n  \n  .pricing-features li {\n    margin-bottom: 10px;\n  }\n  \n  .pricing-button {\n    background-color: #0056b3;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 1rem;\n    transition: background-color 0.3s ease;\n  }\n  \n  .pricing-button:hover {\n    background-color: #4ee36c;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
