import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer";
import Header from "../public/Header";

const PrivacyPolicy = () => {
  return (
<div>
    <Header/>
    <div className="h-auto w-full flex flex-col items-center px-20 py-10 text-gray-700">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">CHÍNH SÁCH BẢO MẬT THÔNG TIN</h1>
<div className="px-6 ">
      <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800 ">Mục đích thu thập thông tin khách hàng</h2>
        <p>
          Khi truy cập và sử dụng các tính năng và dịch vụ trên website Cynosure, Quý khách hàng có thể sẽ được yêu cầu đăng ký với chúng tôi thông tin cá nhân bao gồm họ tên, số điện thoại, email cá nhân… 
        </p>
        <p>
        Cynosure không chịu mọi trách nhiệm liên quan đến pháp luật đối với thông tin Quý khách hàng khai báo trên hệ thống.
        </p>

      </section>
      <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Phạm vi sử dụng thông tin khách hàng</h2>
        <p>
          Website thu thập và sử dụng thông tin cá nhân của Quý khách hàng với mục đích phù hợp, không vi phạm pháp luật và hoàn toàn tuân thủ nội dung của “Chính sách bảo mật” này. Chúng tôi có thể sử dụng những thông tin cá nhân của Quý khách hàng để liên hệ trực tiếp với Quý khách dưới các hình thức như: gửi thư ngỏ, xác nhận lịch đặt hẹn tư vấn với Chuyên gia, thư cảm ơn, tin nhắn, các chương trình của Cynosure cũng như những thay đổi trong chính sách liên quan đến quyền lợi của Quý khách hàng.
        </p>
      </section>

      <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Thời gian lưu trữ thông tin khách hàng</h2>
        <p>
          Dữ liệu cá nhân của Quý khách hàng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân Khách hàng sẽ được bảo mật tuyệt đối trên máy chủ của Website <a href="https://vbigroup.vn/" className="text-blue-500">https://vbigroup.vn/</a>.
        </p>
      </section>

      <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Cam kết bảo mật thông tin cá nhân của Quý khách hàng</h2>
        <ul className="list-disc ml-6">
          <li>
            Thông tin cá nhân của Quý khách hàng cung cấp cho chúng tôi thông qua Website Cynosure được chúng tôi cam kết bảo mật tuyệt đối theo Chính sách bảo mật thông tin này và theo các quy định pháp luật. Việc thu thập và sử dụng thông tin của Quý khách hàng chỉ được thực hiện khi có sự đồng ý của Quý khách hàng hoặc các trường hợp khác do pháp luật quy định.
          </li>
          <li>
            Website Cynosure cam kết không tiết lộ, cung cấp cho bên thứ ba khác những thông tin của Quý khách hàng khi chưa được sự đồng ý từ Quý khách hàng.
          </li>
          <li>
            Website Cynosure không chịu trách nhiệm về tính xác thực cũng như mọi trách nhiệm liên quan đến pháp luật đối với các thông tin Quý khách hàng khai báo.
          </li>
        </ul>
      </section>
      </div>

      <p className="text-center text-gray-100">Trân trọng cảm ơn Quý khách hàng.</p>
    </div>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
