import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../utils/motion'

const steps = [
  { id: 1, title: 'Nhập thông tin đăng ký', description: 'Chào mừng! Thao tác này chỉ mất vài phút và chúng tôi luôn bảo mật thông tin của bạn.' },
  { id: 2, title: 'Xác thực tài khoản', description: 'Kiểm tra email của bạn và nhấp vào liên kết xác thực để kích hoạt tài khoản.' },
  { id: 3, title: 'Xác thực danh tính', description: 'Quá trình này giúp đảm bảo an toàn và bảo mật cho bạn.' },
];

const StepsComponent = () => {
  const [activeStep, setActiveStep] = useState(null);

  const handleStepClick = (stepId) => {
    setActiveStep(activeStep === stepId ? null : stepId);
  };

  return (
    <div className="flex flex-col items-start justify-start">
    
       
    {steps.map((step, index) => (
        <div key={step.id} className="relative">
          <motion.div
            variants={fadeIn('up', 'tween', 1.2, 0.5)}
            className="w-1 h-full violet-gradient"
            style={{
              position: 'absolute',
              top: 7,
              left: '-0.5px',
              height: `${(steps.length) * 80}px`,
            }}
          />
          

            <motion.div
                variants={fadeIn('right', 'tween', 0.2, 1)}
              className="flex items-center cursor-pointer justify-center relative"
              onClick={() => handleStepClick(step.id)}
              whileHover={{ scale: 1.1 }}
            >
              <motion.div
                className="circle"
                style={{
                  width: activeStep === step.id ? '32px' : '16px',
                  height: activeStep === step.id ? '32px' : '16px',
                  backgroundColor: '#3b82f6',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  zIndex: 10,
                  position: 'absolute',
                  top: `${index * 80}px`, // Adjust the spacing between circles
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
                transition={{ duration: 0.3 }}
              ></motion.div>
              <motion.div
                
                className="step-text flex flex-col justify-center text-2xl md:text-2xl lg:text-3xl xl:text-4xl"
                style={{
                  position: 'absolute',
                  left: 'calc(50% + 30px)', // Align description with right edge of the circle
                  top: `calc(${index * 80}px + 50%)`, // Adjust as needed for alignment
                  transform: 'translateY(-50%)',
                  textAlign: 'left',
                  whiteSpace: 'nowrap', // Prevent line breaks
                  
                }}
              >
                <span className="font-bold  text-white" style={{ marginLeft: '10px' }}>{step.title}</span>
                {activeStep === step.id && (
                  <span className="text-2xl py-4 text-gray-400 ml-2 block" style={{ marginLeft: '10px' }}>{step.description}</span>

                )}
              </motion.div>
            </motion.div>
          </div>
        ))}
      </div>
  );
};

export default StepsComponent;
