import React, { useState, useEffect } from 'react';
import { FiUsers, FiHome, FiShoppingCart, FiUser } from 'react-icons/fi'; // Import các icon từ react-icons
import axios from 'axios';
import CryptoJS from 'crypto-js'; // Import CryptoJS
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const API_URL = "https://cynosure.id.vn:5362"; // Replace with your backend API URL
const SECRET_KEY = 'your-secret-key'; // Define your secret key here

const Sidebar = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [currentPage, setCurrentPage] = useState('Dashboard'); // Trang mặc định là Dashboard
  const [userList, setUserList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [adminId, setAdminId] = useState(null); // State để lưu adminId
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [soldCounts, setSoldCounts] = useState({}); // State lưu số lượng sản phẩm đã bán
const [totalUsers, setTotalUsers] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState({ g1: 0, g2: 0, g3: 0 });  
useEffect(() => {
    const adminDataString = localStorage.getItem('id');
	console.log("sting",adminDataString)    
	setAdminId(adminDataString)
//if (adminDataString) {
	
  //    try {
    //    const bytes = CryptoJS.AES.decrypt(adminDataString, SECRET_KEY);
      //  const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	//console.log("adminId:",adminData.id)
        //setAdminId(adminData.id);
     // } catch (error) {
      //  console.error('Lỗi giải mã dữ liệu admin từ localStorage:', error);
      //}
    //}
  }, []);
useEffect(() => {
    if (currentPage === 'Dashboard') {
      const fetchDashboardData = async () => {
        try {
          const responseUsers = await axios.post(`${API_URL}/get_all_users`, {
            id_admin: adminId // Gửi adminId về backend trong yêu cầu POST
          });
          const response = await axios.post(`${API_URL}/count_premium_subscriptions`, {
            id_admin: adminId 
          });
    
          const { data } = response.data;
          setTotalTransactions({
            g1: data.premium_1_count || 0,
            g2: data.premium_2_count || 0,
            g3: data.premium_3_count || 0
          });
          const users = responseUsers.data.data;
          setUserList(users);
          setTotalUsers(users.length);
        } catch (error) {
          console.error('Lỗi khi lấy dữ liệu Dashboard:', error);
        }
      };

      fetchDashboardData();
    }
  }, [adminId, currentPage]);
  useEffect(() => {
    // Hàm gọi API để lấy số lượng sản phẩm đã bán
    const fetchSoldCounts = async () => {
//	if (!adminId) return;
console.log("admin",adminId)
      try {
        const response = await axios.post(`${API_URL}/count_premium_subscriptions`, {
          id_admin: adminId // Gửi adminId về backend trong yêu cầu POST
        });
//if (response.data && response.data.data) {
	
        const { data } = response.data;
        setSoldCounts(data);
  //    } 
//else {
//console.error("khong co data", response.data);
//}
}catch (error) {
        console.error('Lỗi khi lấy số lượng sản phẩm đã bán:', error);
      }
    };
    if (currentPage === 'Quản lý sản phẩm') {
      fetchSoldCounts();
    }
  }, [adminId, currentPage]);

  const buttons = [
    { id: 2, icon: FiHome, label: 'Dashboard' },
    { id: 3, icon: FiShoppingCart, label: 'Quản lý sản phẩm' },
    { id: 4, icon: FiUsers, label: 'Quản lý khách hàng' },
    { id: 1, icon: FiUsers, label: 'Nhân sự' },
  ];

  const handleMouseEnter = (id) => {
    setActiveButton(id);
  };

  const handleMouseLeave = () => {
    setActiveButton(null);
  };

  const handleButtonClick = async (label) => {
    setCurrentPage(label);
    setActiveButton(null); // Clear active button state

    if (label === 'Quản lý khách hàng') {
      try {
        setLoadingUsers(true);
        const response = await axios.post(`${API_URL}/get_all_users`, {
          id_admin: adminId // Gửi adminId về backend trong yêu cầu POST
        });
        setUserList(response.data.data);
	console.log("data:",response.data.data)
 // Lưu danh sách người dùng vào state từ response.data.data
      } catch (error) {
        console.error('Lỗi khi lấy danh sách người dùng:', error);
      } finally {
        setLoadingUsers(false);
      }
    } else if (label === 'Quản lý sản phẩm') {
      // Mock data for products
      const mockProducts = [
        { id: '001', name: 'Gói 1', description: 'Mô tả cho gói 1', sold: soldCounts.premium_1_count || 0 },
        { id: '002', name: 'Gói 2', description: 'Mô tả cho gói 2', sold: soldCounts.premium_2_count || 0 },
        { id: '003', name: 'Gói 3', description: 'Mô tả cho gói 3', sold: soldCounts.premium_3_count || 0 }
      ];
      setProducts(mockProducts);
    }
  };


  const barChartData = {
    labels: products.map(product => product.name),
    datasets: [
      {
        label: 'Số lượng đã bán',
        data: products.map(product => product.sold),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Số lượng sản phẩm đã bán',
      },
    },
  };


  return (
    <div className="flex w-full px-[2%] mb-3">
      {/* Sidebar */}
      <div className="mt-[7vw] bg-gray-500 rounded-xl border border-white bg-opacity-50 text-white w-1/4 h-auto flex flex-col justify-between">
        <div className="p-6">
          <div className="flex items-center justify-between mb-[3vw]">
            <span className="text-[1.7vw] font-semibold">Admin Control</span>
            <button className=" text-gray-400 hover:text-white focus:outline-none focus:text-white">
              <FiUser size={20} />
            </button>
          </div>
          <nav>
            <ul>
              {buttons.map(button => (
                <li
                  key={button.id}
                  className={`flex items-center text-[1.2vw] h-[5vw] mb-5 rounded-lg overflow-hidden transition-colors duration-300 ${
                    activeButton === button.id ? 'bg-lime-400' : 'bg-white'
                  }`}
                  onMouseEnter={() => handleMouseEnter(button.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <button
                    className="block w-full p-3 flex items-center text-gray-800 hover:text-white focus:outline-none"
                    onClick={() => handleButtonClick(button.label)}
                  >
                    <button className="rounded-full bg-gray-700 p-2">
                      <button.icon className="text-white" size={20} />
                    </button>
                    <span className="ml-4">{button.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <p className="p-4"><strong>Trang hiện tại:</strong> {currentPage}</p>
        {/* Thanh footer, có thể thêm thông tin admin hoặc các chức năng khác */}
        <div className="p-4 border-t border-gray-700">
          <span className="text-sm text-gray-400">Admin Name</span>
          
        </div>
      </div>
      
      {/* Box bên phải để hiển thị các state */}
      <div className="relative mt-[7vw] bg-gray-500 rounded-xl w-full border border-white bg-opacity-50 text-white ml-4 h-auto flex flex-col justify-between">
   {currentPage === 'Dashboard' && (
          <div className="p-4 grid grid-cols-2 gap-4">
            <div className="p-4 bg-lime-400 rounded-lg text-black text-lg font-semibold flex flex-col items-center justify-center">
              <span>Tổng số người dùng</span>
              <span>{totalUsers}</span>
            </div>
            <div className="p-4 bg-red-400 rounded-lg text-white text-lg font-semibold flex flex-col items-center justify-center">
              <span>Tổng giao dịch Gói 1</span>
              <span>{totalTransactions.g1}</span>
            </div>
            <div className="p-4 bg-blue-400 rounded-lg text-white text-lg font-semibold flex flex-col items-center justify-center">
              <span>Tổng giao dịch Gói 2</span>
              <span>{totalTransactions.g2}</span>
            </div>
            <div className="p-4 bg-green-400 rounded-lg text-white text-lg font-semibold flex flex-col items-center justify-center">
              <span>Tổng giao dịch Gói 3</span>
              <span>{totalTransactions.g3}</span>
            </div>
          </div>
        )}
     
      {currentPage === 'Quản lý khách hàng' && (
  <div className="p-4">
    {loadingUsers ? (
      <p>Đang tải danh sách người dùng...</p>
    ) : (
      <div className="overflow-auto max-h-[40vw]"> {/* Thay đổi kích thước tùy theo yêu cầu */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-lime-400">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">STT</th>	
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">ID</th>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">Họ và tên</th>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">Email</th>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">001</th>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">002</th>
              <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">003</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
{userList.length > 0 ? (
              userList.map((user, index) => (
                <tr key={user.id} className="hover:bg-gray-100">
                  <td className="px-6 py-4 text-black whitespace-nowrap">{index + 1}</td>
                                    <td className="px-6 py-4 text-black whitespace-nowrap">{user.id}</td>
                  <td className="px-6 py-4 text-black whitespace-nowrap">{user.fullName}</td>
                  <td className="px-6 py-4 text-black whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 text-black whitespace-nowrap">{user["001"].toString()}</td>
                  <td className="px-6 py-4 text-black whitespace-nowrap">{user["002"].toString()}</td>
                  <td className="px-6 py-4 text-black whitespace-nowrap">{user["003"].toString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-6 py-4 text-black whitespace-nowrap" colSpan={6}>Không có dữ liệu người dùng.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )}
  </div>
)}


        {/* Hiển thị danh sách sản phẩm */}
        {currentPage === 'Quản lý sản phẩm' && (
          <div className="absolute top-0 w-full">
            {loadingProducts ? (
              <p>Đang tải danh sách sản phẩm...</p>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-lime-400">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">ID</th>
                      <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">Tên sản phẩm</th>
                      <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">Mô tả</th>
                      <th scope="col" className="px-6 py-3 text-left text-lg font-medium uppercase tracking-wider text-black">Đã bán</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {products.map(product => (
                      <tr key={product.id} className="hover:bg-gray-100">
                        <td className="px-6 py-4 text-black whitespace-nowrap">{product.id}</td>
                        <td className="px-6 py-4 text-black whitespace-nowrap">{product.name}</td>
                        <td className="px-6 py-4 text-black whitespace-nowrap">{product.description}</td>
                        <td className="px-6 py-4 text-black whitespace-nowrap">{product.sold}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
     
            
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
