import React, { memo } from 'react'
import styles from '../styles';


const Button = ({text, textColor, bgColor, IcAfter, onClick}) => {
  return (
    <button
        type='button'
        className={`py-2 px-4 ${textColor} ${bgColor} outline-none rounded-3xl hover:underline flex items-center justify-center gap-1`}
        onClick={onClick}    
    >   

      <span className={styles.butText}>{text}</span>
      {IcAfter && <IcAfter/>}

    </button>
  )
}

export default memo(Button)
