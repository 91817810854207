import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import chatbotIcon from "../../assets/Chat bot.3png-05.png";
import HeaderAdmin from "../system/HeaderAdmin";
import styles from '../../styles';
import { FaPaperPlane } from 'react-icons/fa';

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { sender: "user2", text: "XIN CHÀO CYNOBOT CÓ THỂ GIÚP BẠN ĐIỀU GÌ ?", logo: chatbotIcon },
  ]);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let isBotReplying = false;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const fetchChatbotResponse = async (query) => {
    try {
      console.log("Sending request to backend with query:", query);
      const response = await axios.post("http://4.236.177.128:5362/gemini", {
        messages: [query]
      });
      console.log("Response from backend:", response.data);
      if (response.data.success) {
        const botResponse = response.data.data[0];
        return botResponse;
      } else {
        return "Có lỗi xảy ra, vui lòng thử lại sau.";
      }
    } catch (error) {
      console.error("Error calling backend API:", error);
      return "Có lỗi xảy ra, vui lòng thử lại sau.";
    }
  };

  const handleSendMessage = async () => {
    if (!isBotReplying && message.trim() !== "") {
      isBotReplying = true;
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user1", text: message },
      ]);
      setMessage("");

      try {
        const chatbotResponse = await fetchChatbotResponse(message);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "user2", text: chatbotResponse, logo: chatbotIcon },
        ]);
      } catch (error) {
        console.error("Error handling send:", error);
      }

      isBotReplying = false;
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (inputRef.current) {
        inputRef.current.value += "\n";
        handleInputResize();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputResize = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  return (
    <div className="flex h-screen p-4">
      <div className="w-1/5 p-4 bg-gray-900 rounded-3xl">
        <HeaderAdmin />
        <div className="p-16 gradient-01 text-white font-bold rounded-3xl mb-4 cursor-pointer">
          Gói 1
          <p className="text-sm">Description cho Gói 1</p>
        </div>
        <div className="p-16 gradient-01 text-white font-bold rounded-3xl mb-4 cursor-pointer">
          Gói 2
          <p className="text-sm">Description cho Gói 2</p>
        </div>
        <div className="p-16 gradient-01 text-white font-bold rounded-3xl mb-4 cursor-pointer">
          Gói 3
          <p className="text-sm">Description cho Gói 3</p>
        </div>
      </div>
      <div className="w-4/5 flex flex-col ml-4 p-4 bg-gray-800 rounded-3xl shadow-lg text-2xl">
        <div className="flex-1 overflow-auto bg-gray-900 p-10 rounded-3xl shadow-lg ">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex items-center mb-3 ${
                msg.sender === "user1" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`message max-w-[900px] ${
                  msg.sender === "user1"
                    ? "user-message bg-blue-300 text-blue-800"
                    : "bot-message bg-green-100 text-blue-900"
                } px-5 py-2.5 rounded-3xl`}
              >
                {msg.sender === "user2" && (
                  <img
                    src={msg.logo}
                    alt="Chatbot Icon"
                    className="w-12 h-12 mr-2 inline-block"
                  />
                )}
                <span className="content">{msg.text}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <div className="relative flex items-center w-full max-w-[600px]">
            <textarea
              placeholder="Nhập tin nhắn..."
              value={message}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              className="flex-1 p-2 border rounded-[2rem] resize-none overflow-hidden border-0 py-4 px-4 max-h-[40px] bg-gray-700 w-full text-white"
              style={{ height: "40px" }}
              onInput={handleInputResize}
            />
            <button
              onClick={handleSendMessage}
              className="absolute right-1 bg-green-500 text-white rounded-full flex items-center justify-center"
              style={{ width: "35px", height: "35px" }}
            >
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;