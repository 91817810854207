import React from 'react';
import logo from '../../assets/logo.png'
import { motion } from 'framer-motion'
import styles from '../../styles'
import {  staggerContainer, textVariant } from '../../utils/motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faPlane, faHeartbeat, faGraduationCap, faLaptopCode, faPalette, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Explore from  "../../components/Explore"
import ExperienceCard from  "../../components/ExploreCard"
import ServiceCard from "../../components/Overview"
import BuildJourney from "../../components/Journey"
import MailBox from "../../components/MailBox"
import Footer from "../../components/Footer"
import Header from "../public/Header"
import hinh1 from "../../assets/aboutus1.jpg"; 
import hinh2 from "../../assets/hinh2.jpg"; 

const ABTUS_PATH = "/aboutus";

const Aboutus = () => {
  const goAbtus = () => {
    window.location.href = ABTUS_PATH;
  };
const categories = [
    { id: 1, name: 'Kinh tế', icon: faBusinessTime },
    { id: 2, name: 'Du lịch', icon: faPlane },
    { id: 3, name: 'Y học', icon: faHeartbeat },
    { id: 4, name: 'Giáo dục', icon: faGraduationCap },
    { id: 5, name: 'Công nghệ', icon: faLaptopCode },
    { id: 6, name: 'Nghệ thuật', icon: faPalette },
    { id: 7, name: 'Buôn bán', icon: faShoppingCart }
  ];
  return (
    
    <div className='flex-col m-auto '>        
    <Header/>
  
        <div className='flex items-start justify-start'>
        <section className={`${styles.yPaddings} w-1/2 sm:pl-16 pl-16`}>
 
          <span className={`${styles.AboutHeadText} flex gap-2 mx-7 my-20`}>
            CYNOSURE
          </span>
          <div className={`${styles.aboutustext}  flex gap-2 mx-10 my-20`}>
          <p>CYNOSURE HÂN HẠNH KHI LÀ MỘT CẦU NỐI GIỮA CÁC DỰ ÁN START-UP CỦA SINH VIÊN VỚI CÁC NHÀ ĐẦU TƯ. CHÚNG TÔI CUNG CẤP DỊCH VỤ TƯ VẤN PHÁT TRIỂN KẾ HOẠCH, HỖ TRỢ GIẢI QUYẾT NHỮNG RÀO CẢN, THÁCH THỨC VỀ NGUỒN VỐN CỦA MỘT DỰ ÁN START-UP.</p>
        </div>
            
           
        </section>
        <section className={`${styles.yPaddings} w-1/2 sm:pl-16 pl-16`}>
    
    <span className={`${styles.heroHeading} flex gap-2 justify-end mx-20 my-20`}>
      LÀ AI?
    </span>
    <span className={`${styles.herosText1}  flex gap-2 text-end justify-end mx-20 my-20`} >
    CYNOSURE SẼ ĐỒNG HÀNH CÙNG CÁC BẠN BƯỚC QUA NHỮNG GIAI ĐOẠN KHÓ KHĂN ĐẦU TIÊN TRÊN CON ĐƯỜNG XÂY DỰNG HÀNH TRÌNH LỚN.
    </span>
       
      
   </section>

        </div>
      <div className="flex text-container">
        <span className="center-text">Turn your vision into reality</span>
        <span className="center-text">Turn your vision into reality</span>
        <span className="center-text">Turn your vision into reality</span>
        <span className="center-text">Turn your vision into reality</span>
      </div>

      <div className="flex gap-2 category-container">
        {categories.map(category => (
          <div key={category.id} className="category-item">
            <FontAwesomeIcon icon={category.icon} className="category-icon" />
            <span className="category-text">{category.name}</span>
          </div>
        ))}
      </div>



      <div className="w-full">
  <div className="flex items-center justify-between">
    <div className="flex flex-col items-center">
      <span className={`${styles.herosText} flex gap-2 mx-5 my-[1.8vw]`}>
        TẦM NHÌN
      </span>
      <div className={`${styles.herosText1} flex gap-2 mx-10 my-20 md:my-5`} style={{ maxWidth: 'calc(100% - 35%)' }}>
        <p className="text-center">
          Trở thành nơi uy tín hàng đầu để thế hệ trẻ Việt Nam đặt tham vọng khởi nghiệp. Phát triển mạnh mẽ không chỉ trong Việt Nam mà còn vươn ra thế giới.
        </p>
      </div>
      <img src={hinh1} alt="Image 1" className="w-[43vw] h-auto mb-4 hidden md:block rounded-2xl" />
      </div>
    
    <div className="flex flex-col items-center">
      <span className={`${styles.herosText} my-[1.8vw]`}>
        SỨ MỆNH
      </span>
      <div className={`${styles.herosText1} flex justify-end my-20 md:my-5`} style={{ maxWidth: 'calc(100% - 40%)' }}>
        <p className="text-center">
          Đưa ngành kinh tế Việt Nam phát triển mạnh mẽ với các Start-up hoặc SME, đồng hành cùng họ tìm kiếm những nhà đầu tư chất lượng với mục tiêu phát triển bền vững.
        </p>
      </div>
      <div className="flex flex-col items-center">
        <img src={hinh2} alt="Image 2" className="w-[43vw] h-auto hidden md:block rounded-2xl" />
      </div>
    </div>
  </div>
</div>

        <div classname='m-auto'>
<ExperienceCard/>

</div>

<div classname='m-auto'>
<ServiceCard/>

</div>

<div classname='m-auto'>
<BuildJourney/>

</div>
  
<div classname='m-auto'>
<MailBox/>

</div>

<div className="m-auto mt-[5vw]">
           <Footer />
      </div>
        </div>


  );
};

export default Aboutus;