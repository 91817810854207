import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import CryptoJS from 'crypto-js';
import HeaderAdmin from "../system/HeaderAdmin";

const MYBANK = {
  BANKID: 'icb',
  BANKACC: '100882047059',
};

const packages = [
  { ID: "001", name: "Gói 1", price: "129000" },
  { ID: "002", name: "Gói 2", price: "150000" },
  { ID: "003", name: "Gói 3", price: "00000" },
];

const Payment = () => {
  const { packageId } = useParams();
  const navigate = useNavigate(); // Khai báo useNavigate
  const [userName, setUserName] = useState(''); // State để lưu tên người dùng
  const [transferContent, setTransferContent] = useState(''); // Nội dung chuyển khoản
  const [qrUrl, setQrUrl] = useState(''); // URL mã QR
  const [paymentStatus, setPaymentStatus] = useState(null); // Trạng thái thanh toán
  const [showQr, setShowQr] = useState(true); // State to control QR visibility
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const [intervalId, setIntervalId] = useState(null);

  const selectedPackage = packages.find(pkg => pkg.ID === packageId);

  useEffect(() => {
    // Kiểm tra localStorage để lấy dữ liệu người dùng đã được mã hóa
    const userData = localStorage.getItem('userData');
    if (userData) {
      try {
        // Giải mã dữ liệu người dùng từ localStorage
        const bytes = CryptoJS.AES.decrypt(userData, 'your-secret-key');
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        // Phân tích chuỗi JSON thành đối tượng
        const parsedUserData = JSON.parse(decryptedData);
        setUserName(parsedUserData.id); // Lấy tên người dùng từ dữ liệu đã giải mã
        setTransferContent(`ID ${selectedPackage.ID} User ${parsedUserData.id}`); // Set nội dung chuyển khoản
      } catch (error) {
        console.error('Lỗi khi giải mã hoặc phân tích JSON từ dữ liệu người dùng:', error);
      }
    }
  }, [selectedPackage]); // Hook chỉ chạy khi component được render lại

  useEffect(() => {
    if (selectedPackage && transferContent) {
      const qrUrl = `https://vietqr.co/api/generate/${MYBANK.BANKID}/${MYBANK.BANKACC}/VIETQR.CO/${selectedPackage.price}/${transferContent}?style=2&logo=1&isMask=1&bg=61`;
      setQrUrl(qrUrl);
    }
  }, [transferContent]); // Hook chỉ chạy khi nội dung chuyển khoản thay đổi
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }
);
useEffect(() => {
  // Start countdown timer
  const countdownInterval = setInterval(() => {
    setCountdown(prevCountdown => {
      if (prevCountdown <= 0) {
        clearInterval(countdownInterval);
        setPaymentStatus('Thanh toán thất bại: Hết hạn');
        setShowQr(false);
        return 0;
      }
      return prevCountdown - 1;
    });
  }, 1000);

  // Set interval ID
  setIntervalId(countdownInterval);

  return () => {
    clearInterval(countdownInterval); // Cleanup countdown interval
  };
}, []);

useEffect(() => {
  // Check payment every 10 seconds if selectedPackage and transferContent are present
  if (selectedPackage && transferContent) {
    const paymentInterval = setInterval(() => {
      checkPayment(selectedPackage.price, transferContent);
    }, 10000);

    return () => {
      clearInterval(paymentInterval); // Cleanup payment check interval
    };
  }
}, [selectedPackage, transferContent]);

  const checkPayment = async (price, content) => {
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbw5wupSJHTww0v9sLZe2PZxpEqShihQKqNMCwtAC701Ona2Oy9J185c6GTGCwmGVDnUtQ/exec"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const firstPaid = data.data[0]; // Đọc dòng đầu tiên
      const firstPrice = firstPaid["Giá trị"];
      const firstContent = firstPaid["Mô tả"];
      console.log("Kiểm tra thanh toán:", { firstPrice, firstContent, price, content });
      if (firstPrice >= price && firstContent.includes(content)) {
        setPaymentStatus("Thanh toán của bạn đang được kiểm tra. Vui lòng không rời khỏi trang!");
        await sendPaymentSuccessToBackend(); // Gửi thông tin thanh toán thành công về backend
        setTimeout(() => {
          updateLocalStorage(selectedPackage.ID)
          navigate('/project'); // Chuyển hướng đến trang /chat sau 1s
        }, 1000);
      } else {
        setPaymentStatus(null); // Không thông báo thất bại nếu chưa kiểm tra
      }
    } catch (error) {
      console.error("Lỗi kiểm tra thanh toán:", error);
      setPaymentStatus("Lỗi kiểm tra thanh toán");
    }
  };

  const sendPaymentSuccessToBackend = async () => {
    const userData = localStorage.getItem('userData');
    const bytes = CryptoJS.AES.decrypt(userData, 'your-secret-key');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    const parsedUserData = JSON.parse(decryptedData);

    const paymentInfo = {
      userId: parsedUserData.id,
      packageId: selectedPackage.ID,
    };

    try {
      console.log('Gửi thông tin thanh toán thành công đến backend:', paymentInfo);
  
      const response = await fetch('http://cynosure.id.vn:5362/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentInfo),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");

      }

      console.log('Thông tin thanh toán thành công đã được gửi về backend');
    } catch (error) {
      console.error('Lỗi khi gửi thông tin thanh toán thành công về backend:', error);
    }
  };

  const updateLocalStorage = (packageId) => {
    const userData = localStorage.getItem('userData');
  if (userData) {
    try {
      const bytes = CryptoJS.AES.decrypt(userData, 'your-secret-key');
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const parsedUserData = JSON.parse(decryptedData);

      // Cập nhật gói dựa vào packageId
      parsedUserData[`goi${packageId}`] = !parsedUserData[`goi${packageId}`]; // Đảo trạng thái gói

      // Encrypt and update localStorage
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(parsedUserData), 'your-secret-key').toString();
      localStorage.setItem('userData', encryptedData);
    } catch (error) {
      console.error('Lỗi khi cập nhật thông tin người dùng vào localStorage:', error);
    }
  }
};
      

  if (!selectedPackage) {
    return <div>Package not found</div>;
  }

  return (
    <div className="h-screen">
      <div className="absolute top-0" >
      <HeaderAdmin/>
      </div>
      <div className="px-[10%] py-[8%] mb-[12vw] h-screen">
    <div className="border border-gray-200 bg-gray-700 bg-opacity-30 rounded-xl shadow-lg flex h-full flex-col items-center justify-center">
      <p className="font-bold text-[2vw] text-lime-500">Quét mã QR để cùng đồng hành cùng Cynosure</p>
      <h1 className="mt-4 text-3xl text-white font-bold mb-6 flex">Thanh toán cho<p className="text-lime-500 mb-4">: {selectedPackage.name}</p></h1>
      {showQr && <img src={qrUrl} alt="QR Code" className="border border-lime-500 rounded-xl  w-64 h-64 mb-4" />}      
      <p className="text-xl text-white mb-4">Nội dung chuyển khoản: {transferContent}</p> 
      {paymentStatus && <p className="text-xl text-white mb-4">{paymentStatus}</p>} 
      <p className="text-2xl text-lime-500 mt-4 flex">Vui lòng quét mã QR để thanh toán: <p className="font-bold text-white text-3xl">{selectedPackage.price} VND</p></p>
      <div className="bg-orange-300 rounded-xl mt-2">
      <p className="text-xl text-black mt-4 mb-4 px-4 flex">Đơn hàng sẽ hết hạn sau: <p className="font-bold text-red-900">{Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60}</p></p> 
      </div>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
        onClick={() => navigate(-1)} // Quay lại trang trước
      >
        Quay lại
      </button>
    </div>
    </div>
    </div>
  );
};

export default Payment;