const initState = {
    isLogedIn: true,
    token: 1111
}

const authReducer = (state=initState,action) => {
    switch (action.type) {

        default:
            return state;
    }
}

export default authReducer