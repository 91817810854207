import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';
import Sidebar from './Sidebar';

const Admin = () => {
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(false);
  const navigate = useNavigate();
  console.log(localStorage.getItem('chucvu'),"in cc")

  useEffect(() => {
    const chucvu = localStorage.getItem('chucvu');
    if (!chucvu || chucvu==='undefined') {
       navigate('/admin/login');
    }
    // Add 'navigate' to dependencies array to avoid lint warnings
  }, [navigate]);
  useEffect(() => {
	const handleUnload = () => {
	localStorage.removeItem('chucvu');
	};
window.addEventListener('beforeunload',handleUnload);
return () => {
window.removeEventListener('beforeunload',handleUnload);
};
}, []);
  // Render admin dashboard once logged in
  return (
    <div className="w-full justify-between relative">
	<div className="absolute top-0">
      <HeaderAdmin />
	</div>
      <div className="flex h-screen w-full">
        <Sidebar />
        {/* Add other admin content here */}
      </div>
    </div>
  );
};

export default Admin;
