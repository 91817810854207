import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from "../system/HeaderAdmin";
import './ResetPassword.css'; // Import file CSS để tùy chỉnh giao diện

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Sử dụng useNavigate để chuyển hướng

    const resetPassword = () => {
        if (password !== confirmPassword) {
            setMessage("Mật khẩu không khớp. Vui lòng thử lại.");
            return;
        }

        // Giả định rằng việc đặt lại mật khẩu đã được thực hiện thành công
        setMessage("Mật khẩu đã được đặt lại thành công.");
        navigate('/login'); // Chuyển hướng đến trang login
    }

    return (
        <div className="center-container">
            <HeaderAdmin />
            <div className="box">
                <h2 className="reset-password-header">Đặt lại mật khẩu</h2>
                <div>
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Nhập mật khẩu mới" 
                        required 
                    />
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        placeholder="Nhập lại mật khẩu" 
                        required 
                    />
                    <button onClick={resetPassword}>Đặt lại mật khẩu</button>
                </div>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default ResetPassword;
