
import logo from "../../assets/logo.png"
import React, { useCallback, useState } from 'react'
import { Button } from "../../components"
import icons from "../../utils/icon"
import { useNavigate } from 'react-router-dom'
import { path } from "../../utils/constant"
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from '../../styles';
import { navVariants } from '../../utils/motion'
import  "../../styles/main.css";




function Navbar() {

  return (
    <div>
     <a className='flex items-center justify-between'>
      <Link to={path.HOME}>
         <img
            src={logo}
            alt="logo"
            className="w-[200px] h-[60px] mx-10 my-7 object-cover"
        />
    </Link>
   
        </a>
        </div>
   
      
  );
}

export default Navbar;