// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* StartupConsulting.css */

.startup-consulting-container {
    padding: 20px;
  }
  
  .intro-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .intro-section h1 {
    font-size: 2.5rem;
    color: #f1d6d6;
    font-weight: bold;
  }
  
  .intro-section p {
    font-size: 1.8rem;
    color: #2aec3e;
    margin-top: 10px;
  }
  
  .team-section,
  .organizations-section {
    margin-bottom: 40px;
  }
  
  .team-container,
  .organizations-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .advisor-card,
  .organization-card {
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
  }
  
  .advisor-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .organization-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .footer {
    text-align: center;
    margin-top: 40px;
  }
  
  .footer hr {
    margin-bottom: 10px;
    border: 1px solid #ddd;
  }
  
  .footer p {
    font-size: 1rem;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/StartupConsulting.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;IACI,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,gBAAgB;EAClB;;EAEA;;IAEE,mBAAmB;EACrB;;EAEA;;IAEE,aAAa;IACb,eAAe;IACf,6BAA6B;EAC/B;;EAEA;;IAEE,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":["/* StartupConsulting.css */\n\n.startup-consulting-container {\n    padding: 20px;\n  }\n  \n  .intro-section {\n    text-align: center;\n    margin-bottom: 40px;\n  }\n  \n  .intro-section h1 {\n    font-size: 2.5rem;\n    color: #f1d6d6;\n    font-weight: bold;\n  }\n  \n  .intro-section p {\n    font-size: 1.8rem;\n    color: #2aec3e;\n    margin-top: 10px;\n  }\n  \n  .team-section,\n  .organizations-section {\n    margin-bottom: 40px;\n  }\n  \n  .team-container,\n  .organizations-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n  }\n  \n  .advisor-card,\n  .organization-card {\n    background: white;\n    padding: 20px;\n    margin: 10px;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    width: 250px;\n    text-align: center;\n  }\n  \n  .advisor-card img {\n    width: 100%;\n    border-radius: 8px;\n    margin-bottom: 10px;\n  }\n  \n  .organization-icon {\n    font-size: 2rem;\n    margin-bottom: 10px;\n    color: #333;\n  }\n  \n  .footer {\n    text-align: center;\n    margin-top: 40px;\n  }\n  \n  .footer hr {\n    margin-bottom: 10px;\n    border: 1px solid #ddd;\n  }\n  \n  .footer p {\n    font-size: 1rem;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
