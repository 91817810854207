const initState = {
    userData: {}
}

const userReducer = (state=initState,action) => {
    switch (action.type) {

        default:
            return state;
    }
}

export default userReducer