import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import chatbotIcon from "../../assets/Artboard 8.png";
import HeaderAdmin from "../system/HeaderAdmin";
import { FaPaperPlane, FaMicrophone, FaStopCircle } from 'react-icons/fa';
import CryptoJS from 'crypto-js';
import { FiArrowRightCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const packages = [
  { ID: "001", name: "Gói 1", price: "100000" },
  { ID: "002", name: "Gói 2", price: "200000" },
  { ID: "003", name: "Gói 3", price: "300000" },
];

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { sender: "user2", text: "XIN CHÀO CYNOBOT CÓ THỂ GIÚP BẠN ĐIỀU GÌ ?", logo: chatbotIcon },
  ]);
  const [fullname, setFullname] = useState('');
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [linkUrl, setLinkUrl] = useState('');
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let isBotReplying = false;

  const [isLoading, setIsLoading] = useState(false);
  const [isRagMode, setIsRagMode] = useState(false);
  const [ragResult, setRagResult] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const [error, setError] = useState(null);
  const [link, setLink] = useState('');

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isTranscribeMode, setIsTranscribeMode] = useState(false);
  const [transcribeResult, setTranscribeResult] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [userPackage, setUserPackage] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      let userData = localStorage.getItem("userData");
      if (userData) {
        userData = CryptoJS.AES.decrypt(userData, 'your-secret-key').toString(CryptoJS.enc.Utf8);
        userData = JSON.parse(userData);
        if (userData.fullName) {
          setFullname(userData.fullName);
        }
        if (userData.goi001 && userData.goi002) {
          setUserPackage(["goi001", "goi002"]);
        } else if (userData.goi001) {
          setUserPackage(["goi001"]);
        } else if (userData.goi002) {
          setUserPackage(["goi002"]);
        } else if (userData.goi003) {
          setUserPackage(["goi003"]);
        }
      }
    }
  }, [navigate]);

  const fetchChatbotResponse = async (query) => {
    try {
      const response = await axios.post("https://cynosure.id.vn:5362/gemini", {
        messages: [query]
      });

      if (response.data.success) {
        const botResponse = response.data.data[0];
        return botResponse;
      } else {
        return "Có lỗi xảy ra, vui lòng thử lại sau.";
      }
    } catch (error) {
      console.error("Error calling backend API:", error);
      return "Có lỗi xảy ra, vui lòng thử lại sau.";
    }
  };

  const fetchRagResponse = async (query) => {
    try {
      const response = await axios.post("https://cynosure.id.vn:5362/process_questions", {
        messages: query
      });

      if (response.data.success) {
        const botResponse = response.data.data[0];
        return botResponse;
      } else {
        return "Có lỗi xảy ra, vui lòng thử lại sau.";
      }
    } catch (error) {
      console.error("Error calling backend API:", error);
      return "Có lỗi xảy ra, vui lòng thử lại sau.";
    }
  };

  const handleSendMessage = async () => {
    if (!isBotReplying && message.trim() !== "") {
      isBotReplying = true;
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user1", text: message },
      ]);
      setMessage("");

      try {
        let chatbotResponse;
        if (isRagMode) {
          chatbotResponse = await fetchRagResponse(message);
        } else if (isTranscribeMode) {
          chatbotResponse = await fetchTranscribeResponse(message);
        } else {
          chatbotResponse = await fetchChatbotResponse(message);
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "user2", text: chatbotResponse, logo: chatbotIcon },
        ]);
      } catch (error) {
        console.error("Error handling send:", error);
      }

      isBotReplying = false;
    }
  };

  const fetchTranscribeResponse = async (query) => {
    try {
      const response = await axios.post("https://cynosure.id.vn:5362/gemini", {
        messages: [query],
        context: transcribeResult
      });

      if (response.data.success) {
        const botResponse = response.data.data[0];
        return botResponse;
      } else {
        return "Có lỗi xảy ra, vui lòng thử lại sau.";
      }
    } catch (error) {
      console.error("Error calling backend API:", error);
      return "Có lỗi xảy ra, vui lòng thử lại sau.";
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (inputRef.current) {
        inputRef.current.value += "\n";
        handleInputResize();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputResize = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  const handleCancel = () => {
    setSelectedFile(null); // Xóa selectedFile khi hủy
    setLink(''); // Xóa link khi hủy
    setIsPdf(false); // Đặt lại isPdf về false khi hủy
    setError(null); // Xóa lỗi khi hủy
    setIsRagMode(false);
    setRagResult(null);
    setIsTranscribeMode(false);
    setTranscribeResult(null);
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.ondataavailable = (event) => {
          setAudioBlob(event.data);
        };
        mediaRecorder.start();
        setIsRecording(true);
      })
      .catch(err => {
        console.error('Lỗi khi ghi âm:', err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleTranscribe = async () => {
    setIsLoading(true);
    setIsTranscribeMode(true);

    try {
      const formData = new FormData();
      formData.append('link', audioBlob, 'audio.wav');

      const response = await fetch('https://cynosure.id.vn:5362/transcribe', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        setTranscribeResult(data.transcription);
        setMessage(data.transcription);
      } else {
        const errorData = await response.json();
        console.error('Lỗi khi chuyển ngữ:', errorData.error || 'Lỗi không xác định');
      }
    } catch (err) {
      console.error('Lỗi khi chuyển ngữ:', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 50 * 1024 * 1024) { // check if file is less than 50MB
      setSelectedFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
      setError(null); // Clear any previous errors
    } else {
      setError("File size must be less than 50MB.");
    }
  };


  const handleLinkChange = (e) => {
    setLink(e.target.value);
    if (e.target.value.endsWith('.pdf')) {
      setIsPdf(true);
      setError(null);
    } else {
      setIsPdf(false);
      setError('Vui lòng nhập một link PDF.');
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile && link.trim() === '') {
      setError('Please select a file or provide a PDF link.');
      return; // Stop execution if no file or link
    }
  
    setIsLoading(true);
    setIsRagMode(true);
  
    try {
      let formData = new FormData();
  
      if (selectedFile) {
        formData.append('file', selectedFile);
        formData.append('role', 'false');
  
        const response = await fetch('https://cynosure.id.vn:5362/process_document', {
          method: 'POST',
          body: formData
        });
  
        if (response.ok) {
          const data = await response.json();
          setRagResult(data);
        } else {
          console.error('Error sending file:', response.statusText);
        }
      } else if (link.trim() !== '') {
        const response = await fetch('https://cynosure.id.vn:5362/process_document', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ link, role: 'true' })
        });
  
        if (response.ok) {
          const data = await response.json();
          setRagResult(data);
        } else {
          console.error('Error sending link:', response.statusText);
        }
      }
  
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleLinkSubmit = async () => {
    setIsLoading(true);
    setIsRagMode(true);

    try {
      const response = await fetch('https://cynosure.id.vn:5362/process_document', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ link, role: 'true' })
      });

      if (response.ok) {
        const data = await response.json();
        setRagResult(data);
      } else {
        console.error('Lỗi khi tải link');
      }
    } catch (err) {
      console.error('Lỗi:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen p-4">
      <div className="w-1/5 p-4 bg-gray-900 rounded-3xl relative flex-col">
        <HeaderAdmin />
        {Array.isArray(userPackage) && userPackage.includes("goi001") && (
            <div className="p-4 md:p-8 bg-gradient-to-br from-purple-600 to-purple-800 text-white font-bold rounded-3xl mb-4">
            {!isRecording && (
              <button onClick={startRecording} className="bg-green-500 text-white p-2 rounded">
                <FaMicrophone /> Ghi âm
              </button>
            )}
            {isRecording && (
              <button onClick={stopRecording} className="bg-red-500 text-white p-2 rounded">
                <FaStopCircle /> Dừng ghi âm
              </button>
            )}
            {audioBlob && (
              <button onClick={handleTranscribe} disabled={isLoading} className="bg-blue-500 text-white p-2 rounded">
                {isLoading ? 'Đang chuyển ngữ...' : 'Chuyển ngữ'}
              </button>
            )}
          </div>
        )}
        {Array.isArray(userPackage) && userPackage.includes("goi002") && (
          <div className="p-4 md:p-8 bg-gradient-to-br from-purple-600 to-purple-800 text-white font-bold rounded-3xl mb-4">
            <h2 className="text-lg font-bold mb-4">RAG Mode</h2>
            <div className="mb-4">
              <label className="block text-white mb-2">Tải PDF lên:</label>
              <input type="file" accept="application/pdf" onChange={handleFileChange} className="block w-full text-sm text-gray-200 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" />
              {fileUrl && <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="text-gray-200">View PDF</a>}
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">Hoặc điền PDF Link:</label>
              <input type="text" onChange={handleLinkChange} className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white" />
            </div>
            <button 
    onClick={handleSubmit} 
    className="bg-green-500 text-white p-2 rounded w-full"
    disabled={!selectedFile && link.trim() === ''} 
>
              Send to RAG
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <button onClick={handleCancel} className="bg-red-500 text-white p-2 rounded w-full mt-2">
              Cancel
            </button>
          </div>
        )}
        <div className="p-4 md:p-8 bg-gradient-to-br from-purple-600 to-purple-800 text-white w-[90%] font-bold rounded-3xl mb-4 absolute bottom-0">
        <p>Xin chào {fullname}</p>
        <Link to="/premium">
      <button
        className="bg-gradient-to-gr from-purple-600 to-purple-800 text-lime-500 font-bold rounded-3xl p-2 flex items-center justify-end"
      >
        Tham khảo các ưu đãi khác
        <FiArrowRightCircle className="ml-2 text-2xl" />
      </button>
    </Link>
        </div>
        
      </div>
      <div className="w-4/5 flex flex-col ml-4 p-4 bg-gray-800 rounded-3xl shadow-lg text-2xl">
        <div className="flex-1 overflow-auto bg-gray-900 p-10 rounded-3xl shadow-lg">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex items-center mb-3 ${
                msg.sender === "user1" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`message ${
                  msg.sender === "user1"
                    ? "user-message bg-blue-300 text-blue-800 ml-[10vw]"
                    : "bot-message bg-green-100 text-blue-900 mr-[10vw]"
                } px-5 py-2.5 rounded-3xl`}
              >
                {msg.sender === "user2" && (
                  <img
                    src={msg.logo}
                    alt="Chatbot Icon"
                    className="w-8 h-8 mr-2 inline-block"
                  />
                )}
                <span className="content">{msg.text}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <div className="relative flex items-center w-full max-w-[60vw]">
            <textarea
              placeholder="Nhập tin nhắn..."
              value={message}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              className="flex-1 p-2 border rounded-[2rem] resize-none overflow-hidden border-0 py-4 px-4 max-h-[4vw] bg-gray-700 w-full text-white"
              style={{ height: "4vw" }}
              onInput={handleInputResize}
            />
            <button
              onClick={handleSendMessage}
              className="absolute right-1 bg-green-500 text-white rounded-full flex items-center justify-center"
              style={{ width: "3.5vw", height: "3.5vw" }}
            >
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
