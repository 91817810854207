import logo from "../assets/logo-01.png"
import ha from "../assets/ha.png"
import hoa from "../assets/hoa.png"
import huy from "../assets/huy.png"
import nhi from "../assets/nhi.png"
import trinh from "../assets/trinh.png"
import van from "../assets/van.png"

export const socials = [
    {
      name: 'twitter',
      url: '/twitter.svg',
    },
    {
      name: 'linkedin',
      url: '/linkedin.svg',
    },
    {
      name: 'instagram',
      url: '/instagram.svg',
    },
    {
      name: 'facebook',
      url: '/facebook.svg',
    },
  ];
  
  export const exploreWorlds = [
    {
      id: 'world-1',
      imgUrl: 'public/planet-01.png',
      title: 'The Hogwarts',
    },
    {
      id: 'world-2',
      imgUrl: 'public/planet-02.png',
      title: 'The Upside Down',
    },
    {
      id: 'world-3',
      imgUrl: 'public/planet-03.png',
      title: 'Kadirojo Permai',
    },
    {
      id: 'world-4',
      imgUrl: 'public/planet-04.png',
      title: 'Paradise Island',
    },
    {
      id: 'world-5',
      imgUrl: 'public/planet-05.png',
      title: 'Hawkins Labs',
    },
  ];

export  const experiences = [
    {
      title: "Gọi vốn - Tìm nhà đầu tư",
      company_name: "Cynosure",
      icon: logo,
      iconBg: "#383E56",
      date: "March 2020 - April 2021",
      points: [
        "Kêu gọi nguồn vốn từ các nhà đầu tư để thực hiện một dự án hoặc mục tiêu kinh doanh cụ thể.",
        
      ],
    },
    {
      title: "Xây dựng bản báo cáo tài chính",
      company_name: "Cynosure",
      icon: logo,
      iconBg: "#E6DEDD",
      date: "Jan 2021 - Feb 2022",
      points: [
        "Quản lý doanh nghiệp hiệu quả, giúp nhà đầu tư, chủ doanh nghiệp và các bên liên quan khác đánh giá hiệu quả hoạt động và tình hình tài chính của doanh nghiệp.",
      ],
    },
    {
      title: "Tư Vấn",
      company_name: "Cynosure",
      icon: logo,
      iconBg: "#383E56",
      date: "Jan 2022 - Jan 2023",
      points: [
        "Một ý tưởng sẽ không thành hiện thực nếu không được định hướng ngay những bước đầu. Cynosure sẽ hỗ trợ bạn đánh giá dự án, tư vấn định hướng phát triển phù hợp với thị trường.",
        ],
    },
    {
      title: "Tìm kiếm dự án Start-up",
      company_name: "Cynosure",
      icon: logo,
      iconBg: "#E6DEDD",
      date: "Jan 2023 - Present",
      points: [
        "Bạn đang tìm kiếm những dự án Start-up tiềm năng để đầu tư?",
        "Bạn muốn đồng hành cùng những ý tưởng sáng tạo, đầy nhiệt huyết và có khả năng bứt phá?",
        "Hãy cùng Cynosure khám phá những dự án Start-up đầy hứa hẹn!",
      
      ],
      
    },
    {
      title: "Lập kế hoạch kinh doanh",
      company_name: "Cynosure",
      icon: logo,
      iconBg: "#383E56",
      date: "Jan 2023 - Present",
      points: [
        "Xác định mục tiêu, chiến lược và hành động cần thiết để đạt được thành công cho doanh nghiệp, đánh giá môi trường kinh doanh, xác định thị trường mục tiêu, phát triển chiến lược marketing và bán hàng, lập kế hoạch tài chính và quản lý rủi ro.",
      
      ],
      
    },
  ];
  
export  const services = [
    {
      title: "VU QUOC HUY - CEO",
      icon: huy,
    },
    {
      title: "LE THI VAN VAN - CD",
      icon: van,
    },
    {
      title: "HOANG THI MY HOA - CMO",
      icon: hoa,
    },
    {
      title: "TRAN NHAT TRINH - CTO",
      icon: trinh,
    },
    {
      title: "HUYNH HONG HA - CFO",
      icon: ha,
    },
    {
      title: "DUONG HOANG NHI - CBDO",
      icon: nhi,
    },

  ];