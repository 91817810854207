import React from 'react';
import Header from './Header'; // Import component Header từ file tương ứng
import Footer from "../../components/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <Header /> {/* Component Header được sử dụng ở đây */}
      <div className="h-auto flex flex-col items-center py-10 text-gray-700">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">ĐIỀU KHOẢN VÀ ĐIỀU KIỆN SỬ DỤNG</h1>
      <div className="px-6">

        {/* Section A: Điều khoản và Điều kiện chung */}
        <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">A. Điều khoản và Điều kiện chung:</h2>
          <p>
            Chúng tôi có ​​đầy đủ quyền để công bố/xuất bản công khai các tư vấn miễn phí của các Chuyên gia cho khách hàng mà những tư vấn này được thực hiện thông qua Website. Việc công bố có thể thực hiện tại Cynosure hoặc dưới bất kỳ hình thức nào nhằm chia sẻ kiến thức pháp lý này ra bên ngoài xã hội theo các phương thức hoặc hình thức được luật pháp Việt Nam cho phép. Tuy nhiên, không có bất kỳ thông tin chi tiết nào liên quan đến nhân thân người dùng sẽ được hiển thị. Trong trường hợp bạn muốn bất kỳ câu hỏi nào của mình và câu trả lời tương ứng của Chuyên gia cho câu hỏi đó không được công bố/xuất bản công khai, xin vui lòng sử dụng bất kỳ lựa chọn dịch vụ hoặc tính năng khác, ví dụ, tư vấn với chúng tôi qua email, điện thoại, hoặc gặp mặt trực tiếp Chuyên gia của Cynosure.
          </p>
        </section>
        
        {/* Section B: Các điều khoản và điều kiện cụ thể */}
        <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">B. Các điều khoản và điều kiện cụ thể:</h2>
          <ul className="list-disc pl-5">
            <li>Sử dụng Website: Người dùng cam kết cung cấp các thông tin trung thực và chính xác và chịu hoàn toàn trách nhiệm đối với cam kết này. Chúng tôi không có nghĩa vụ phải tiến hành bất kỳ hành động gì để xác thực bất kỳ thông tin nào được cung cấp bởi người dùng, cho dù là liên quan đến nội dung, tính chính xác hay bất kỳ tính chất nào khác liên quan đến thông tin đó. Với ý định cung cấp các dịch vụ tốt nhất có thể, chúng tôi có thể yêu cầu bạn chia sẻ thêm thông tin khi cần thiết.</li>
            <li>Website hoạt động như thế nào?: Nếu bạn chọn để gửi Câu hỏi miễn phí trên Website, bạn sẽ không phải trả bất kỳ khoản phí nào; tuy nhiên, câu hỏi miễn phí có thể cần có một khoảng thời gian để được trả lời bởi Chuyên gia. Ngoài ra, bất kỳ câu trả lời bạn nhận được từ Chuyên gia để giải đáp Câu hỏi miễn phí sẽ không cấu thành bất kỳ mối quan hệ Chuyên gia - khách hàng nào và sẽ được hiển thị công khai và có thể được xem bởi tất cả người dùng.</li>
            <li>Phạm vi dịch vụ: Cynosure cung cấp dịch vụ thông qua Website không chịu trách nhiệm cho bất kỳ sự kiện y tế, luật pháp hoặc tài chính hoặc bất kỳ hệ quả nào liên quan đến dịch vụ được thực hiện thông qua việc sử dụng Website.</li>
            <li>Hồ sơ của người dùng tại Cynosure: Hồ sơ của bạn được tạo ra để lưu trữ trực tuyến hồ sơ tư vấn và thông tin liên quan đến các vấn đề/câu hỏi của bạn. Bất kỳ thông tin nào được cung cấp trong quá trình tư vấn qua website, điện thoại, video hoặc tư vấn trực tiếp đều sẽ được lưu vào Hồ sơ của Cynosure. Việc bạn đồng ý cung cấp cho chúng tôi các thông tin chính xác, định kỳ xem xét lại và cập nhật thông tin khi cần thiết sẽ giúp chúng tôi phục vụ bạn với tất cả kiến thức của mình một cách tốt nhất.</li>
            <li>Bảo vệ các thông tin độc quyền: Bạn đồng ý rằng dịch vụ, bao gồm tất cả các phần mềm và thông tin, kể cả ấn phẩm điện tử và xuất bản, có chứa các bí mật kinh doanh và các tài sản sở hữu trí tuệ khác là tài sản thuộc sở hữu của Cynosure. Các tài sản này được bảo vệ bởi các quy định pháp luật về quyền tác giả, nhãn hiệu, quyền sở hữu trí tuệ khác và sẽ chỉ được sử dụng hoặc truy cập theo các quy định của Chính sách này.</li>
            <li>Miễn trừ trách nhiệm bảo đảm: Bạn hoàn toàn hiểu rõ và đồng ý rằng: rủi ro trong việc sử dụng Website này là duy nhất thuộc về bạn. Tất cả các nội dung và dịch vụ trên Website được cung cấp chỉ trên cơ sở “như thực trạng/như sẵn có”. Trong phạm vi pháp luật hiện hành cho phép, chúng tôi từ chối tất cả các bảo đảm dưới bất kỳ hình thức nào, dù là rõ ràng hay ngụ ý, bao gồm, nhưng không giới hạn, những bảo đảm và điều kiện ngụ ý về thương mại, sự hài lòng về chất lượng, sự phù hợp cho một mục đích hoặc sử dụng cụ thể, như là kết quả mà bạn có thể đạt được nhờ sử dụng Website, kết quả mà bạn có thể đạt được nhờ bất kỳ mối quan hệ nào với Chuyên gia, và sự không vi phạm. Cynosure hoặc bất kỳ người cấp phép nào hoặc bất kỳ các đơn vị trực thuộc của Cynosure không thực hiện bất kỳ tuyên bố hoặc bảo đảm nào liên quan đến bất kỳ Chuyên gia, nhưng không giới hạn, khả năng bất kỳ Chuyên gia nào có thể mang lại kết quả mong muốn cho bạn.</li>
          </ul>
        </section>

        {/* Section C: Quyền tài phán */}
        <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">C. Quyền tài phán</h2>
          <p>
            Chính sách này cấu thành toàn bộ sự hiểu biết của các bên và được các bên đồng ý thực hiện theo quyền tài phán của toà án Việt Nam, được chi phối và hiểu theo luật của nước Cộng hòa Xã hội Chủ nghĩa Việt Nam. Bất kỳ tranh chấp nào với Cynosure đều chịu sự xét xử độc quyền của toà án có thẩm quyền tại Việt Nam.
          </p>
        </section>

        {/* Section D: Quyền sửa đổi Chính sách */}
        <section className="p-4 mx-20 bg-white rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">D. Quyền sửa đổi Chính sách</h2>
          <p>
            Bạn đồng ý và xác nhận rằng Chính sách này có thể được sửa đổi theo thời gian mà không cần có thông báo và tự chịu trách nhiệm cập nhật theo định kỳ phiên bản mới nhất có trên Cynosure. Bất kỳ sự sửa đổi hoặc thay đổi như vậy sẽ có tính ràng buộc và có hiệu lực ngay khi phát hành Chính sách sửa đổi hoặc thay đổi cho (các) tính năng hoặc dịch vụ trên Website. Trừ khi chúng tôi thông báo bằng cách khác, các điều khoản này sẽ kết hợp và thay thế cho bất kỳ điều khoản nào khác có liên quan.
          </p>
        </section>
        </div>

        <p className="text-center text-gray-100">Trân trọng cảm ơn Quý khách hàng.</p>
</div>
        <Footer/>
      </div>
  );
};

export default TermsAndConditions;
