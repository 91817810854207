import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/logo.png';
import HeaderAdmin from "../system/HeaderAdmin";

const API_URL = "https://cynosure.id.vn:5362"; // Thay thế bằng URL API backend của bạn

const Register = () => {
  const location = useLocation();
  const initialEmail = location.state?.email || ''; // Nhận email từ state
  const initialAccountType = location.state?.accountType || ''; // Nhận loại tài khoản từ state
  const [selectedOption, setSelectedOption] = useState(initialAccountType);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State lưu trữ thông báo lỗi
  const [continueClicked, setContinueClicked] = useState(false); // Used to track submission
  const [user, setUser] = useState(null); // State lưu trữ thông tin người dùng
  const [loading, setLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleDateOfBirthChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  const handleAgreeTermsChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      console.log("Yêu cầu gửi đi:", { email, password, selectedOption, fullName, dateOfBirth, agreeTerms });
      const response = await axios.post(`${API_URL}/signup`, {
        email,
        password,
        role: selectedOption,
        fullName,
        dateOfBirth,
      });

      if (response.status === 200) {
        setUser({
          email,
          fullName,
          dateOfBirth,
          role: selectedOption,
        });
        setContinueClicked(true); // Redirect or handle the new user registration state
        console.log("Đăng ký thành công:", {
          email,
          fullName,
          dateOfBirth,
          role: selectedOption,
        });
        setContinueClicked(true); // Redirect or handle the new user registration state
      } else {
        setErrorMessage(response.data.message || "Đăng ký thất bại. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setErrorMessage("Đăng ký thất bại. Vui lòng thử lại.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
<div className="absolute top 0">
      <HeaderAdmin />
      </div>
            <div className='flex h-screen items-center'>
        <div className='w-1/2 flex items-end justify-end'>
          <img
            src={logo}
            alt='Logo'
            className='w-34 h-34 object-contain'
          />
        </div>
        <div className='w-1/2 flex items-center justify-center'>
          <div className="w-[500px] h-120 boxed p-10 rounded-3xl shadow-md" style={{ marginTop: '50px' }}>
            <h1 className="text-2xl text-gray-100 font-bold mb-4">Đăng ký</h1>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-100">Chọn loại tài khoản:</label>
              <div className="mt-1 flex items-center">
                <input
                  type="radio"
                  className="mr-2"
                  value="startup"
                  checked={selectedOption === 'startup'}
                  onChange={handleOptionChange}
                />
                <label className="mr-4 text-lime-300">Start-up</label>
                <input
                  type="radio"
                  className="mr-2"
                  value="investor"
                  checked={selectedOption === 'investor'}
                  onChange={handleOptionChange}
                />
                <label className="text-lime-300">Nhà đầu tư</label>
              </div>
            </div>
            {selectedOption && (
              <div>
                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-100">Địa chỉ email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full px-3 py-2 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-100">Mật khẩu:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="w-full px-3 py-2 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-100">Họ và tên:</label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={handleFullNameChange}
                      className="w-full px-3 py-2 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-100">Ngày tháng năm sinh:</label>
                    <input
                      type="date"
                      value={dateOfBirth}
                      onChange={handleDateOfBirthChange}
                      className="w-full px-3 py-2 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={handleAgreeTermsChange}
                        className="form-checkbox"
                      />
                      <span className="ml-2 text-lime-300">Tôi đồng ý với các điều khoản</span>
                    </label>
                  </div>
                  {/* Enable the button only when all conditions are met */}
                  {fullName && dateOfBirth && agreeTerms && (

                       <button className='w-full gradient-01 text-white p-2 rounded-xl mb-4' type="submit" disabled={loading} onClick={handleSubmit}>
                       {loading ? 'Đang đăng ký...' : 'Đăng ký'}
                     </button>
                 
                  )}
                </div>
                {continueClicked && <Navigate to={`/login`} state={user} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
