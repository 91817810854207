export const path = {
    HOME: '/',
    LOGIN: 'login',
    SIGNIN: 'signup',
    ABTUS: 'aboutus',
    PREMIUM: 'premium',
    PROJECT: 'project',
    CHATBOT: 'chat',
    ADMIN: 'admin',
    FORGOT: 'forgot-password',
    RESET: 'reset-password',
    ERROR: 'error-404-page',
    GEMINI: 'gemini-chat',
    PAY: 'payment',
    POLY: 'chinh-sach',
    DIEUKHOAN :'dieu-khoan',
}