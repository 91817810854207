import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import styles from "../styles";
import { services } from "../constants";
import { fadeIn, textVariant } from "../utils/motion";
import SectionWrapper from "./SectionWrapper";

const ServiceCard = ({ index, title, icon }) => (
    <Tilt className="xs:w-[250px] w-full">
      <motion.div variants={fadeIn("right", "spring", index * 0.5, 0.75)}>
        <div className="rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col relative">
          <img
            src={icon}
            alt={title}
            className="service-icon"
          />
            <h3 className="service-title z-10 ">{title}</h3>
        </div>
      </motion.div>
    </Tilt>
  );

const About = () => {
    return (
      <>
        <motion.div variants={textVariant()}>
          <div className="px-6">
          <p className={styles.sectionSubText}>Introduction</p>
          <h2 className={styles.sectionHeadText}>THE TEAM</h2>
          </div>
        </motion.div>
  
        <div className="mt-20 flex flex-wrap justify-center">
          {services.slice(0, 3).map((service, index) => (
            <ServiceCard key={service.title} index={index} {...service} />
          ))}
        </div>
        <div className="my-20"></div>
        <div className="mt-20 flex flex-wrap justify-center">
          {services.slice(3, 6).map((service, index) => (
            <ServiceCard key={service.title} index={index + 3} {...service} />
          ))}
        </div>
      </>
    );
  };
  

export default SectionWrapper(About, "about");