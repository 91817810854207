import React, { useState } from 'react';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    // Xử lý tìm kiếm dữ liệu tại đây (ví dụ: gọi hàm tìm kiếm từ API)
  };

  return (
    <div className="search-bar-container">
      {isSearchVisible && (
        <input
          type="text"
          className="search-input"
          placeholder="Nhập từ khóa tìm kiếm..."
          value={searchTerm}
          onChange={handleInputChange}
        />
      )}
      <div className="search-icon" onClick={handleSearchIconClick}>
        <i className="fas fa-search"></i>
      </div>
    </div>
  );
};

export default SearchBar;
