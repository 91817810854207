import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate, Link } from 'react-router-dom';

const SECRET_KEY = 'your-secret-key';

const packagesInfo = {
  '001': {
    title: 'Gói Premium cho Speech To Text',
    description: 'Chuyển đổi giọng nói thành văn bản. Gói dịch vụ cơ bản Speech To Text là công cụ đơn giản và hiệu quả giúp chuyển đổi giọng nói tiếng Việt thành văn bản một cách nhanh chóng và chính xác. Với khả năng nhận diện giọng nói tự nhiên và xử lý âm thanh, gói dịch vụ này lý tưởng cho các cuộc họp, ghi chú hàng ngày và tạo tài liệu một cách dễ dàng. Đây là giải pháp đáng tin cậy để nâng cao hiệu quả làm việc và tổ chức thông tin trong môi trường ngôn ngữ tiếng Việt.',
  },
  '002': {
    title: 'Gói Premium cho RAG',
    description: 'Retrieval-Augmented Generation. Tự động hóa truy xuất thông tin. Gói dịch vụ Nâng Cao RAG sử dụng công nghệ tiên tiến để tự động hóa quá trình truy xuất thông tin từ các nguồn dữ liệu khổng lồ. RAG kết hợp khả năng của các mô hình học sâu và các công cụ tìm kiếm để cung cấp câu trả lời chính xác và chi tiết. Đây là giải pháp hiệu quả cho việc nghiên cứu, phân tích dữ liệu và tạo nội dung có giá trị trong lĩnh vực tiếng Việt.',
  },
  '003': {
    title: 'Gói Chuyên Nghiệp RAG',
    description: 'Retrieval-Augmented Generation - Chuyên Nghiệp. Phân tích và tạo nội dung thông minh. Gói dịch vụ Chuyên Nghiệp RAG là công cụ đắc lực cho việc phân tích dữ liệu phức tạp và tạo ra nội dung thông minh, chất lượng cao. RAG giúp tổ chức xử lý và đánh giá dữ liệu từ nhiều nguồn khác nhau, đáp ứng nhu cầu tạo báo cáo chi tiết, phân tích thị trường và tối ưu hóa chiến lược kinh doanh bằng tiếng Việt.',
  },
};

const UserInfo = () => {
  const [userData, setUserData] = useState(null);
  const [userPackages, setUserPackages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const bytes = CryptoJS.AES.decrypt(storedUserData, SECRET_KEY);
        const userDataString = bytes.toString(CryptoJS.enc.Utf8);
        const user = JSON.parse(userDataString);
        setUserData(user);

        // Lọc các gói mà người dùng đã đăng ký
        const userPackages = Object.keys(packagesInfo).filter(
          packageId => user[`goi${packageId}`]
        );

        setUserPackages(userPackages);
      } catch (error) {
        console.error("Lỗi giải mã dữ liệu người dùng từ localStorage:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userData) {
      try {
        const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(userData), SECRET_KEY).toString();
        localStorage.setItem('userData', encryptedUserData);
      } catch (error) {
        console.error("Lỗi mã hóa dữ liệu người dùng:", error);
      }
    }
  }, [userData]);

  if (!userData) {
    return <div className="text-center text-red-500">Không tìm thấy dữ liệu người dùng.</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-6 rounded-lg shadow-lg px-[18%]">
      <h1 className="text-3xl font-bold mb-6 text-purple-800">Thông tin người dùng</h1>
      <div className="mb-4 p-4 bg-white rounded-lg shadow-md">
        <p className="text-xl">Họ tên: {userData.fullName}</p>
        <p className="text-xl">Email: {userData.email}</p>
        <p className="text-xl">ID: {userData.id}</p>
      </div>
      {userPackages.length > 0 ? (
        userPackages.map(packageId => (
          <div key={packageId} className="mb-4 p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-green-800">{packagesInfo[packageId].title}</h2>
            <p className="text-lg mt-2">{packagesInfo[packageId].description}</p>
          </div>
        ))
      ) : (
        <div className="p-4 bg-white rounded-lg shadow-md">
          <p>Quý khách chưa đăng ký gói premium. <Link to="/premium" className="text-blue-500">Đăng ký ngay</Link></p>
        </div>
      )}
        <p className="text-gray-600 mt-4">Trang này đang được cập nhật. Vui lòng quay lại sau.</p>
      <button
        onClick={() => navigate('/')}
        className="mt-6 px-4 py-2 bg-purple-800 text-white rounded-lg shadow-md hover:bg-purple-900"
      >
        Quay về trang chủ
      </button>
    </div>
  );
};

export default UserInfo;
